import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { ENV, s3Url } from '../../actions/config';
import '../../stylesheets/Merchant.css';

const MerchantSelector = ({ t, onChange, value }) => {
    const style4Bg = { height: '30px' };
    let userSettings = JSON.parse(localStorage.getItem('profileDetails')) || {};
    
    const [searchTerm, setSearchTerm] = useState('');
    const [merchants, setMerchants] = useState([]);
    const [selectedMerchant, setSelectedMerchant] = useState(null);
    const [loading, setLoading] = useState(false);
    const [shouldFetch, setShouldFetch] = useState(true);

    // Debounced API call to fetch merchants
    const fetchMerchants = debounce(async (query) => {
        if (query.length < 2 || !shouldFetch) {
            setMerchants([]);
            return;
        }
        setLoading(true);
        try {
            const response = await axios.get(`${ENV.API}api/admin/merchant/search/${userSettings.currency || 'USD'}/${query}/0`);
            setMerchants(response.data.merchants || []);
        } catch (error) {
            console.error('Error fetching merchants:', error);
        }
        setLoading(false);
    }, 300);

    // Fetch merchants based on search input
    useEffect(() => {
        if (shouldFetch) fetchMerchants(searchTerm);
        return () => fetchMerchants.cancel();
    }, [searchTerm, shouldFetch]);

    // // Set initial selected merchant from `value`
    // useEffect(() => {
    //     if (value && value.categoryId) {
    //         axios.get(`${ENV.API}api/admin/merchant/${value.categoryId}`)
    //             .then(response => {
    //                 const merchant = response.data;
    //                 setSelectedMerchant(merchant);
    //                 setSearchTerm(merchant.name);
    //             })
    //             .catch(error => console.error("Error fetching merchant:", error));
    //     }
    // }, [value]);

    // When a merchant is selected
    const handleSelectMerchant = (merchant) => {
        setSelectedMerchant(merchant);
        setSearchTerm(merchant.name);
        setMerchants([]); // Hide dropdown
        setShouldFetch(false); // Stop API calls

        // Call onChange to update parent state
        if (onChange) {
            onChange({
                merchantId: merchant.aggregatorMerchantId,
                merchantName: merchant.name,
                merchantIcon: merchant.logoUrl
            });
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setSelectedMerchant(null);
        setShouldFetch(true);
    };

    return (
        <table className='table-category-selector'>
            <tbody>
                <tr>
                    <td style={{ width: '3.9rem' }}>
                        {selectedMerchant && selectedMerchant.logoUrl ? (
                            <img
                                src={selectedMerchant.logoUrl}
                                alt={selectedMerchant.name}
                                className="leftMargin10 circle cursor-pointer"
                                style={style4Bg}
                            />
                        ) : (
                            <img
                                src={s3Url + "icon_accounts_trolley_grey.png"}
                                className="leftMargin10 circle"
                                style={style4Bg}
                            />
                        )}
                    </td>
                    <td>
                        <div className="merchant-selector">
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder={t('SEARCH_MERCHANT')}
                                className="merchant-input"
                            />
                            {loading && <div className="loading">{t('LOADING')}...</div>}
                            {merchants.length > 0 && (
                                <ul className="merchant-dropdown">
                                    {merchants.map((merchant) => (
                                        <li key={merchant._id} onClick={() => handleSelectMerchant(merchant)}>
                                            <img
                                                src={merchant.logoUrl ? merchant.logoUrl : s3Url + "icon_accounts_trolley_grey.png"}
                                                alt={merchant.name}
                                                className="leftMargin10 circle"
                                                style={style4Bg}
                                            />
                                            {merchant.name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default withTranslation()(connect(null, {})(MerchantSelector));

import React, {Component} from 'react';
import {withTranslation, Trans} from 'react-i18next';
import {
    Row,
    Col,
    Icon,
    Collection,
    CollectionItem,
    Modal,
    Button
} from 'react-materialize';
import {unescape} from 'lodash';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {FormattedNumber} from "react-intl";
import InfiniteScroll from 'react-infinite-scroller';
import {
    LONG_MONTHS as _months
} from './common/ConstData';
import Tabs from "./common/Tabs";
import {
    getShortDate,
    sortArrayDesc,
    getShortDateYr,
    getRecurringDate,
    getDateTimeFormat,
    getDayDiffFromToday,
    getRecurringCategory,
    getDayNegativeDiffFromToday,
    isCreator,
    getAccountDetailUIForBill
} from './common/Methods';
import {
    getPaidBills,
    getOverdueBills,
    getUpcomingBills,
    getRecurringBills,
    deleteTransaction,
    addUpdateBill,
    updateBillsList,
    markBillPaid,
    fetchBillPaymentDetails
} from "../actions/billActions";
import {
    getBillingStats,
} from "../actions/dashboardActions";
import {
    getCategories,
    getServiceProviders,
    getSingleServiceProvider
} from "../actions/authActions";
import Loader from './Loader';
import BillsForm from './AddEditBills';
import MarkBill from './MarkBills';
import Swal from 'sweetalert2';
import {
    getRecurringDataById
} from '../actions/billActions';
import moment from 'moment';
import {iconUrl, s3Url, RIGHT_ARROW_ICON, umediaUrl} from "../actions/config";
import { completeRecurrenceDescription } from '../_helpers/genericFn';
class Bills extends Component {
    constructor(props) {
        super(props);
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        let categories = [];
        this._list=[]
        let serviceProviders = [];
        if (!!localStorage.getItem('userCategories')) {
            categories = JSON.parse(localStorage.getItem('userCategories'));
        } else {
            this.props.getCategories();
        }
        if (!!localStorage.getItem('serviceProviders')) {
            serviceProviders = JSON.parse(localStorage.getItem('serviceProviders'));
        } else {
            this.props.getServiceProviders();
        }
        this.state = {
            year,
            month,
            currentActiveTab: window.location.hash.replace('#', '').toLowerCase() || 'upcoming',
            currentMonthTransactions: [],
            currentTransaction: {},
            currentDayTransactions: [],
            isOnSingleTransaction: false,
            stateCategories: categories,
            stateServiceProviders: serviceProviders,
            userSettings: JSON.parse(localStorage.getItem('profileDetails')) || {},
            transactionType: '',
            upcomingPageNumber: 0,
            upcomingTransactions: [],
            upcomingDataCallInProgress: false,
            hasMoreUpcomingTransactions: true,
            paidPageNumber: 0,
            paidTransactions: [],
            paidDataCallInProgress: false,
            hasMorePaidTransactions: true,
            overduePageNumber: 0,
            overdueTransactions: [],
            recurringTransactions: [],
            overdueDataCallInProgress: false,
            hasMoreOverdueTransactions: true,
            currentTransactionIndex: '',
            editSingleRecord: true,
            billPaymentList: []
        };
        this.tabsReload = {
            upcoming: false,
            paid: false,
            overdue: false,
            recurring: false
        };
        this.updateList = {
            paid: false,
            overdue: false,
            upcoming: false,
            recurring: true
        }
    }

    getAlertBox = (message, type) => {
      const t = this.props.t;
        Swal.fire({
          title: t(message),
            position: 'bottom-start',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
            background: type === 'success' ? '#8dc44e' : '#f1521c',
            customClass: {
                title: 'sweet-alert',
                container: 'sweet-alert-container'
            }
        });
    };

    componentWillReceiveProps(nextProps) {
        const t = nextProps.t;
        if (nextProps.bills) {
            if (nextProps.bills.error) {
                this.getAlertBox(nextProps.bills.error.message, 'error');
            }
            if(!nextProps.bills.BILL_DATA_LOADING && nextProps.bills.billPaymentList.length > 0){
                this.setState({
                    billPaymentList:nextProps.bills.billPaymentList
                })
            }
            if (!nextProps.bills.BILL_DATA_LOADING && nextProps.bills.BILL_DATA_ACTION === 'DELETE_BILL') {
                this.setState({
                    [this.state.currentActiveTab + 'Transactions']: nextProps.bills[this.state.currentActiveTab + 'Transactions'],
                    isOnSingleTransaction: false
                });
                this.getAlertBox(t('ENTERY_DELETED'), 'success');
            } else if (!nextProps.bills.BILL_DATA_LOADING && nextProps.bills.BILL_DATA_ACTION === 'ADD_BILL') {
                this.setState({
                    [this.state.currentActiveTab + 'Transactions']: nextProps.bills[this.state.currentActiveTab + 'Transactions']
                });
                this.getAlertBox(t('BILL_ADDED'), 'success');
            } else if (!nextProps.bills.BILL_DATA_LOADING && nextProps.bills.BILL_DATA_ACTION === 'UPDATE_BILL') {
                this.setState({
                    [this.state.transactionType + 'Transactions']: nextProps.bills[this.state.transactionType + 'Transactions']
                });
                this.getAlertBox(t('BILL_UPDATED'), 'success');
            } else if (!nextProps.bills.BILL_DATA_LOADING && nextProps.bills.BILL_DATA_ACTION === 'BILL_MARKED') {
                this.setState({
                    [this.state.transactionType + 'Transactions']: nextProps.bills[this.state.transactionType + 'Transactions'],
                    currentTransaction: {...this.state.currentTransaction, ...nextProps.bills[this.state.transactionType + 'Transactions'][this.state.currentTransactionIndex]}
                });
                this.getAlertBox(t('BILL_MARKED'), 'success');
                if(this.state.currentTransaction) this.props.fetchBillPaymentDetails(this.state.currentTransaction.localIdLong)
            } else if (!nextProps.bills.BILL_DATA_LOADING && nextProps.bills.BILL_DATA_ACTION === 'GET_RECURRING') {
                this.setState({
                    currentTransaction: {...this.state.currentTransaction, ...nextProps.bills.recurringData}
                });
            } else if (!nextProps.bills.BILL_DATA_LOADING && nextProps.bills.BILL_DATA_ACTION === 'LIST_UPDATE') {
                this.setState({
                    [this.state.currentActiveTab + 'Transactions']: nextProps.bills[this.state.currentActiveTab + 'Transactions']
                });
            }
        }
    }

    componentDidMount() {
        this.props.getRecurringBills({});
        this.props.getBillingStats({
            month: this.state.month,
            year: this.state.year
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.bills.UPCOMING_BILLING_DATA_LOADING === true
            && this.props.bills.UPCOMING_BILLING_DATA_LOADING === false) {
            this.setState({
                upcomingTransactions: this.state.upcomingTransactions
                    .concat(this.props.bills.upcomingTransactions),
                upcomingDataCallInProgress: false,
                upcomingPageNumber: this.state.upcomingPageNumber + 1,
                hasMoreUpcomingTransactions: this.props.bills.upcomingTransactions.length !== 0
            });
        }
        if (prevProps.bills.PAID_BILLING_DATA_LOADING === true
            && this.props.bills.PAID_BILLING_DATA_LOADING === false) {
            this.setState({
                paidTransactions: this.state.paidTransactions
                    .concat(this.props.bills.paidTransactions),
                paidDataCallInProgress: false,
                paidPageNumber: this.state.paidPageNumber + 1,
                hasMorePaidTransactions: this.props.bills.paidTransactions.length !== 0
            });
        }
        if (prevProps.bills.OVERDUE_BILLING_DATA_LOADING === true
            && this.props.bills.OVERDUE_BILLING_DATA_LOADING === false) {
            this.setState({
                overdueTransactions: this.state.overdueTransactions
                    .concat(this.props.bills.overdueTransactions),
                overdueDataCallInProgress: false,
                overduePageNumber: this.state.overduePageNumber + 1,
                hasMoreOverdueTransactions: this.props.bills.overdueTransactions.length !== 0
            });
        }
        if (prevProps.bills.RECURRING_BILLING_DATA_LOADING === true
            && this.props.bills.RECURRING_BILLING_DATA_LOADING === false) {
            this.setState({
                recurringTransactions: this.state.recurringTransactions
                    .concat(this.props.bills.recurringTransactions)
            });
        }
    }

    loadUpcomingTransactions = () => {
        this.updateList.upcoming = true;
        if (this.state.upcomingDataCallInProgress === false) {
            this.setState({upcomingDataCallInProgress: true});
            this.props.getUpcomingBills({page: this.state.upcomingPageNumber});
        }
    };

    loadPaidTransactions = () => {
        this.updateList.paid = true;
        if (this.state.paidDataCallInProgress === false) {
            this.setState({paidDataCallInProgress: true});
            this.props.getPaidBills({page: this.state.paidPageNumber});
        }
    };

    loadOverdueTransactions = () => {
        this.updateList.overdue = true;
        if (this.state.overdueDataCallInProgress === false) {
            this.setState({overdueDataCallInProgress: true});
            this.props.getOverdueBills({page: this.state.overduePageNumber});
        }
    };

    getCategorydetails = (_categoryId, _type, _userId) => {
        if (_type > 2) _type = 1; // TODO: HACK
        const categories = this.state.stateCategories;
        const foundCategories = categories
            .filter(({type, categoryId, userId}) =>
                (type === _type && categoryId === _categoryId)
            );
        if (foundCategories.length > 1) {
            return foundCategories
                .find(
                    ({userId}) => userId === _userId);
        } else {
            return foundCategories[0] || {};
        }
    };

    gotoSingleTransaction = (currentTransaction, transactionType, currentTransactionIndex) => {
        if(currentTransaction.localIdLong && currentTransaction.amountPaid>0){
            this.props.fetchBillPaymentDetails(currentTransaction.localIdLong)
        }
        this.setState({
            isOnSingleTransaction: true,
            currentTransaction,
            transactionType,
            currentTransactionIndex: currentTransactionIndex,
            billPaymentList: []
        });
    };

    setActiveTab = (tab) => {
        this.setState({
            currentActiveTab: tab.id
        });
        if (this.updateList[tab.id] && this.tabsReload[tab.id]) {
            this.props.updateBillsList(this.state.year, tab.id);
            this.tabsReload[tab.id] = false;
        }
    };

    getServiceProviderInfo =  (_providerId,transaction={}) => {
        if(_providerId){
            if(!this._list.includes(_providerId)) {
                this._list.push(_providerId)
                let serviceProvider = JSON.parse(localStorage.getItem('serviceProviders')).find(({providerId}) => providerId === `${_providerId}`);
                // if service provider unavailable, it is fetches singularly
                if(!serviceProvider){
                    this.props.getSingleServiceProvider(_providerId,data=>{
                        if(data) {
                            this.setState({stateServiceProviders:JSON.parse(localStorage.getItem('serviceProviders'))})
                            this.getTitleIcon(transaction)
                        }
                    })
                }
                return serviceProvider || {};
            }
            else{
                return JSON.parse(localStorage.getItem('serviceProviders')).find(({providerId}) => providerId === `${_providerId}`)||{};
            }
        }
    };

    getTitleIcon = (trn) => {
        let calcTitle = '', icon;
        let removeBackgroundColorCheck = false;
        if (trn.serviceProviderId && +trn.serviceProviderId > 0) {
            const serviceProvider = this.getServiceProviderInfo(trn.serviceProviderId,trn);
            if (serviceProvider.providerName !== null) {
                calcTitle = `${serviceProvider.providerName} `;
            }
            if (serviceProvider.logoURL) {
                icon = `${s3Url}${serviceProvider.logoURL}.png`;
                removeBackgroundColorCheck = true;
            }
        }
        if (trn.category) {
            calcTitle += trn.category.name && trn.category.name.toLowerCase() !== 'others' ? unescape(trn.category.name) : unescape(trn.title);
            if (!(!!icon)) {
                icon = iconUrl + trn.category.iconUrl + '.png';
            }
        }
        return {calcTitle, icon, removeBackgroundColorCheck};
    };

    reloadAllTabs = () => {
        this.tabsReload = {
            upcoming: true,
            paid: true,
            overdue: true,
            recurring: true
        };
        this.tabsReload[this.state.currentActiveTab] = false;
    };

    addUpdateBills = (data) => {
        let dataObj = {...data};
        this.reloadAllTabs();
        if (data._id) {
            dataObj = {...this.state.currentTransaction, ...data};
            dataObj.category = this.getCategorydetails(dataObj.categoryId, dataObj.type);
            dataObj = {...dataObj, ...this.getTitleIcon(dataObj)};
            this.setState({
                currentTransaction: {},
                isOnSingleTransaction: false
            });
            delete dataObj.createTime;
            if (this.state.editSingleRecord) {
                delete dataObj.recurringCategoryId;
                delete dataObj.recurringCount;
                delete dataObj.repeatTillCount;
                delete dataObj.repeatTillDate;
                delete dataObj.recurringServerId;
            }
        }
        this.props.addUpdateBill(dataObj, this.state.year, this.state.month, this.state.currentActiveTab);
    };

    onDeleteBills = () => {
        const t = this.props.t;
        const currentTransaction = this.state.currentTransaction;
        let deleteMessage = t('BILL_DELETE_MESSAGE_1');
        if (this.state.currentActiveTab === 'recurring' || currentTransaction.recurringServerId || currentTransaction.recurringIdLong || currentTransaction.recurringId) {
            deleteMessage = t('BILL_DELETE_MESSAGE_1');
        }
        let billId = {_id: this.state.currentTransaction._id, type: 4};
        if(this.state.currentActiveTab === 'recurring') {
            billId = {recurringServerId: this.state.currentTransaction._id, type: 4};
        }
        let singleRecord = true;
        if ((this.state.currentActiveTab !== 'paid') && (currentTransaction.recurringServerId || currentTransaction.recurringIdLong || currentTransaction.recurringId)) {
            deleteMessage =t('DELETE_INCOME_MESSAGE');
            singleRecord = false;
        }
        if(this.state.currentActiveTab === 'paid'){
            Swal.fire({
                title: t('DELETE'),
                text: deleteMessage,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor:'#b8b8b8',
                confirmButtonText: t('DELETE'),
                cancelButtonText: t('CANCEL')
            }).then((result) => {
                if (result.value) {
                    this.reloadAllTabs();
                    if ((this.state.currentActiveTab !== 'paid') && (currentTransaction.recurringServerId || currentTransaction.recurringIdLong || currentTransaction.recurringId)) {
                        if(currentTransaction.recurringIdLong) {
                            billId = {
                                recurringIdLong: currentTransaction.recurringIdLong,
                                type: 4
                            };
                        }else if(currentTransaction.recurringServerId) {
                            billId = {
                                recurringServerId: currentTransaction.recurringServerId,
                                type: 4
                            };
                        }else if(currentTransaction.recurringId) {
                            billId = {
                                recurringId: currentTransaction.recurringId,
                                type: 4,
                                deviceId: currentTransaction.deviceId
                            };
                        }
                    }
                    this.props.deleteTransaction(billId, this.state.year, this.state.month, this.state.currentActiveTab, singleRecord);
                } else if (result.dismiss === 'cancel') {
                    if (!singleRecord) {
                        // this.reloadAllTabs();
                        // this.props.deleteTransaction(billId, this.state.year, this.state.month, this.state.currentActiveTab, !singleRecord);
                    }
                }
            });

        }
        else {
            Swal.fire({
                title: t('DELETE'),
                text: deleteMessage,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: singleRecord ? '#b8b8b8' : '#d33',
                confirmButtonText: singleRecord ? t('DELETE') : t('DELETE_ALL'),
                cancelButtonText: singleRecord ? t('CANCEL') : t('DELETE_THIS')
            }).then((result) => {
                if (result.value) {
                    this.reloadAllTabs();
                    if ((this.state.currentActiveTab !== 'paid') && (currentTransaction.recurringServerId || currentTransaction.recurringIdLong || currentTransaction.recurringId)) {
                        if (currentTransaction.recurringIdLong) {
                            billId = {
                                recurringIdLong: currentTransaction.recurringIdLong,
                                type: 4
                            };
                        } else if (currentTransaction.recurringServerId) {
                            billId = {
                                recurringServerId: currentTransaction.recurringServerId,
                                type: 4
                            };
                        } else if (currentTransaction.recurringId) {
                            billId = {
                                recurringId: currentTransaction.recurringId,
                                type: 4,
                                deviceId: currentTransaction.deviceId
                            };
                        }
                    }
                    this.props.deleteTransaction(billId, this.state.year, this.state.month, this.state.currentActiveTab, singleRecord);
                } else if (result.dismiss === 'cancel') {
                    if (!singleRecord) {
                        this.reloadAllTabs();
                        this.props.deleteTransaction(billId, this.state.year, this.state.month, this.state.currentActiveTab, !singleRecord);
                    }
                }
            });
        }
    };

    openAddBillModel = async () => {
        await this.closeBillsModel();
        this.setState({
            openBillModel: true,
            addNewBill: true,
            editSingleRecord: false
        }, () => {
            const divData = window.document.getElementById('addBillModel');
            divData.scrollTop = 0;
        });
    };

    openMarkForm = async () => {
        await this.closeBillsModel();
        this.setState({
            openMarkModel: true
        }, () => {
            const divData = window.document.getElementById('markBillModel');
            divData.scrollTop = 0;
        });
    };

    getRecurringObj = (editableRecord = true) => {
        if (this.state.currentTransaction.recurringId || this.state.currentTransaction.recurringServerId || this.state.currentTransaction.recurringIdLong) {
            let recurringObj = {};
            if(this.state.currentTransaction.recurringIdLong) {
                recurringObj = {
                    recurringIdLong: this.state.currentTransaction.recurringIdLong
                }
            }else if(this.state.currentTransaction.recurringServerId) {
                recurringObj = {
                    recurringServerId: this.state.currentTransaction.recurringServerId
                }
            }else if(this.state.currentTransaction.recurringId) {
                recurringObj = {
                    recurringId: this.state.currentTransaction.recurringId,
                    type: 1,
                    deviceId: this.state.currentTransaction.deviceId
                }
            }
            if(this.state.currentActiveTab !== 'paid') {
                this.props.getRecurringDataById(recurringObj, editableRecord);
            }
        }
    };

    openEditBillModel = async () => {
        const t = this.props.t;
        await this.closeBillsModel();
        const currentTransaction = this.state.currentTransaction;
        if (this.state.currentActiveTab !== 'paid' && (currentTransaction.recurringServerId || currentTransaction.recurringIdLong || currentTransaction.recurringId)) {
            Swal.fire({
                title: t('EDIT'),
                text: t(this.state.currentActiveTab !== 'recurring' ? 'EDIT_INCOME_MESSAGE' : 'EDIT_RECURRING_MESSAGE'),
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: this.state.currentActiveTab !== 'recurring' ? '#3085d6' : '#b8b8b8',
                confirmButtonText: t('EDIT_ALL'),
                customClass: {
                    actions: this.state.currentActiveTab === 'recurring' ? 'content-ltr' :'',
                },
                cancelButtonText: t(this.state.currentActiveTab !== 'recurring' ? 'EDIT_THIS' : 'CANCEL')
            }).then((result) => {
                if (result.value) {
                    this.getRecurringObj(false);
                    this.setState({
                        openBillModel: true,
                        addNewBill: false,
                        editSingleRecord: false
                    }, () => {
                        const divData = window.document.getElementById('addBillModel');
                        divData.scrollTop = 0;
                    });
                } else if (result.dismiss === 'cancel') {
                    if(this.state.currentActiveTab !== 'recurring') {
                        this.getRecurringObj(true);
                        this.setState({
                            openBillModel: true,
                            addNewBill: false,
                            editSingleRecord: true
                        }, () => {
                            const divData = window.document.getElementById('addBillModel');
                            divData.scrollTop = 0;
                        });
                    }
                }

            });
        } else {
            this.getRecurringObj(true);
            this.setState({
                openBillModel: true,
                addNewBill: false,
                editSingleRecord: true
            }, () => {
                this.setState({'toloadchild': (this.state.toloadchild || 1)+1})
                const divData = window.document.getElementById('addBillModel');
                divData.scrollTop = 0;
            });
        }
    };

    closeBillsModel = () => {
        let divData = window.document.getElementById('addBillModel');
        divData.scrollTop = 0;
        divData = window.document.getElementById('markBillModel');
        divData.scrollTop = 0;
        this.setState({
            openBillModel: false,
            openMarkModel: false
        });
    };

    setMarkData = (formData) => {
        this.props.markBillPaid(formData, this.state.year, this.state.month, this.state.currentActiveTab);
        this.props.fetchBillPaymentDetails(this.state.currentTransaction.localIdLong)
        this.reloadAllTabs();
        this.tabsReload[this.state.currentActiveTab] = false;
    };

    reOpenMarkModel = async () => {
        await this.openMarkForm();
    };

    getServiceProviderObj = () => {
        if (this.state.currentTransaction && this.state.currentTransaction.serviceProviderId) {
            const serviceProvider = JSON.parse(localStorage.getItem('serviceProviders')).find(({providerId}) => +providerId === this.state.currentTransaction.serviceProviderId);
            return serviceProvider && serviceProvider.paymentURL ?
                <div className='add-icon'>
                    <a href={serviceProvider.paymentURL} target="_blank"
                       className="btn p-2 wave-btn btn-flat payment-btn"
                       type="button" name="credit_card">
                        <i className="material-icons payment-icon bill-icon">credit_card</i>
                    </a>
                    <h6 htmlFor="credit_card" style={{textAlign: 'center'}}><Trans>PAY</Trans></h6>
                </div> : null
        }
    };
    //returns the autopaid div
    getAutoPaidDiv= () => {
        if (this.state.currentTransaction ) {
            return this.state.currentTransaction.autoPaid ?
                    <div className='add-icon full-icon-btn'>
                        <Button flat waves="light"
                                type="button" name="mark"  className="wave-btn">
                            <img style={{height: '30px'}} src={iconUrl + 'auto_paid.png'}/>
                        </Button>
                        <h6 htmlFor="mark" style={{textAlign: 'center'}}>
                            <Trans>AUTO_PAID</Trans>
                        </h6>
                    </div>: null
        }
    };
    getPaymentsDiv = () => {
        const t = this.props.t
        const billPaymentList = this.state.billPaymentList
        if (this.state.currentTransaction ) {
            return billPaymentList.length > 0?<>
                {billPaymentList.map((payment,index)=>{
                return <table style={{
                    margin: '0',  
                    marginTop: '15px',
                    width: '100%',
                }}><tr style={{width: '100%'}}><td><p className='margin-0 width-100 font-size-1point1-rem'>{getShortDateYr(payment.time, t)}</p></td><td><span style={{float: 'right', paddingRight: '10px'}}><p className='font-size-1point1-rem margin-0'><FormattedNumber
                value={payment.amount}
                style='currency'
                currency={this.state.userSettings.currencyDetails.currencyCode || 'USD'}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
                /></p></span></td><td></td></tr><tr><td colSpan='2'>{getAccountDetailUIForBill(this.props.auth,payment.accountId,true,payment.createdUserId)}</td></tr></table>})}
            </>:null
        }
    }
    //returns the Created user info div
    getCreatedUserInfoDiv= () => {
        if (this.state.currentTransaction ) {
            return <div className='add-icon full-icon-btn'>
                        <div className="multi-user-creator">
                        {JSON.parse(localStorage.getItem(this.state.currentTransaction.createdUserId)).ownerDetail}
                        </div>
                        <div className='grey-text'>
                            <Trans>CREATED_BY</Trans>
                        </div>
                    </div>
        }
    };

    removeDetailDropdown=()=>{
        this.setState({
            isOnSingleTransaction: false
        });
    };

    render() {
        const {
            currentTransaction = {},
            transactionType,
            stateCategories,
            stateServiceProviders,
            userSettings: {currencyDetails},
            upcomingPageNumber,
            upcomingTransactions,
            hasMoreUpcomingTransactions,
            paidPageNumber,
            paidTransactions,
            hasMorePaidTransactions,
            overduePageNumber,
            overdueTransactions,
            hasMoreOverdueTransactions,
            recurringTransactions
        } = this.state;
        const {
            bills: {
                PAID_BILLING_DATA_LOADING = false,
                UPCOMING_BILLING_DATA_LOADING = false,
                OVERDUE_BILLING_DATA_LOADING = false,
                RECURRING_BILLING_DATA_LOADING = false,
                BILL_DATA_LOADING = false
            },
            dashboard: {
                billsChartData: {
                    overdue = 0,
                    paid = 0,
                    upcoming = 0,
                }
            },
            auth: {
                serviceProviders,
                userCategories,
            },
            t
        } = this.props;
        if (stateCategories && userCategories && stateCategories.length === 0 && userCategories.length > 0) {
            this.setState({stateCategories: userCategories});
        }
        if (serviceProviders && stateServiceProviders.length === 0 && serviceProviders.length > 0) {
            this.setState({stateServiceProviders: serviceProviders});
        }
        return (
            <div>
                <div>
                    <Row className='mb-0 mobile-view'>
                        <Col s={12} m={12} l={12} style={{lineHeight: '30px'}}>
                            &nbsp;
                        </Col>
                    </Row>
                    <Row className='mb-0'>
                        <Col s={12} m={12} l={6}  className='billing-tabs tab-mobile-fixed'>
                            <Tabs
                                activeTab={{
                                    id: this.state.currentActiveTab
                                }}
                                onClick={this.setActiveTab}
                            >
                                <Tabs.Tab
                                    title={
                                        <div className='tab-title' title={t('RECURRING')}>
                                            <Trans>RECURRING</Trans>
                                            <br/>
                                            &nbsp;
                                        </div>
                                    }
                                    id='recurring'
                                >
                                    <div className='fix-height-tab bills-tab-height-top'>
                                        <Collection>
                                            {
                                                recurringTransactions.length ?
                                                    sortArrayDesc(recurringTransactions, 'recurringCategoryId')
                                                        .map(trn =>
                                                            Object.assign(trn,
                                                                {
                                                                    category: this.getCategorydetails(trn.categoryId, trn.type, trn.userId)
                                                                })
                                                        )
                                                        .map(trn => Object.assign(trn, this.getTitleIcon(trn)))
                                                        .map((trn, index) =>
                                                            <CollectionItem key={trn._id} className={index === this.state.currentTransactionIndex && this.state.transactionType === 'recurring' && this.state.isOnSingleTransaction ? 'active-record' : ''}>
                                                                <table
                                                                    className='no-padding UpcomingTable'
                                                                    style={{cursor: 'pointer'}}
                                                                    onClick={() => this.gotoSingleTransaction(trn, 'recurring', index)}
                                                                >
                                                                    <tbody>
                                                                    <tr>
                                                                        <td rowSpan='2' width="13%">
                                                                            {trn.removeBackgroundColorCheck ? <img
                                                                                src={trn.icon}
                                                                                className='leftMargin10 circle'
                                                                            /> : <img
                                                                            src={trn.icon}
                                                                            className='leftMargin10 circle'
                                                                            style={{backgroundColor: trn.category.iconColor}}/>}
                                                                        </td>
                                                                        <td>
                                                                            <div className='date-label'>
                                                                                {trn.type==4 ? trn.title ? trn.title : trn.calcTitle : trn.calcTitle}
                                                                            </div>
                                                                        </td>
                                                                        <td  className='right-align'>
                                                                            <span className="nowrap amount-font-size">
                                                                                <FormattedNumber
                                                                                    value={trn.amount}
                                                                                    style='currency'
                                                                                    currency={currencyDetails.currencyCode || 'USD'}
                                                                                    minimumFractionDigits={2}
                                                                                    maximumFractionDigits={2}
                                                                                />
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='grey-text'> 
                                                                        {  completeRecurrenceDescription(trn, this.props.t)}
                                                                            {
                                                                            //     trn.recurringCategoryId === 0
                                                                            //         ? <span>
																			// 	{getRecurringCategory(trn.recurringCategoryId, 1, t)}
																			// </span>
                                                                            //         : <span>
																			// 	<Trans>EVERY</Trans>&nbsp;
                                                                            //         {getRecurringCategory(trn.recurringCategoryId, trn.recurringCount, t)}
                                                                            //         {' '+RIGHT_ARROW_ICON+' '}
                                                                            //         {getRecurringDate(trn.recurringCategoryId, trn.time, t)}
																			//</span>
                                                                            }
                                                                        </td>
                                                                        <td rowSpan='1' width='20%' className='right-align'>
                                                                            <div className='flex-box-recurring right'>
                                                                            <Icon className='grey-text' right>refresh</Icon>
                                                                            {!isCreator(trn.createdUserId, this.state.userSettings.userId) && localStorage.getItem(trn.createdUserId)? <td>{this.props.auth && this.props.auth.groupImageList && this.props.auth.groupImageList[trn.createdUserId]? <img
                                                                         className='zero-margin height-20' src={this.props.auth.groupImageList[trn.createdUserId]}/>:<h5 className='zero-margin' style={{float: 'right'}}>{JSON.parse(localStorage.getItem(trn.createdUserId)).ownerDetail.slice(0,1).toUpperCase()}</h5>}</td>: null}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </CollectionItem>
                                                        ) :
                                                    <CollectionItem>
                                                        <div className='date-label center-align grey-text'>
                                                            <Trans>NO_DATA_FOUND</Trans>
                                                        </div>
                                                    </CollectionItem>
                                            }
                                        </Collection>
                                    </div>
                                </Tabs.Tab>
                                <Tabs.Tab
                                    title={
                                        <div className='tab-title' title={t('PAID')}>
                                            <Trans>PAID</Trans>
                                            <br/>
                                            <b>
                                                <span className="nowrap">
                                                    <FormattedNumber
                                                        value={paid}
                                                        style='currency'
                                                        currency={currencyDetails.currencyCode || 'USD'}
                                                        minimumFractionDigits={2}
                                                        maximumFractionDigits={2}
                                                        title={t('PAID')}
                                                    />
                                                </span>
                                            </b>
                                        </div>
                                    }
                                    id='paid'
                                >
                                    <div className='fix-height-tab bills-tab-height-top'>
                                        <InfiniteScroll
                                            pageStart={paidPageNumber}
                                            loadMore={this.loadPaidTransactions}
                                            hasMore={hasMorePaidTransactions}
                                            loader={<div key={0}><Trans>LOADING</Trans>...</div>}
                                            threshold={10}
                                            useWindow={false}
                                        >
                                            <Collection>
                                                {
                                                    paidTransactions.length ?
                                                        paidTransactions
                                                            .map(trn =>
                                                                Object.assign(trn,
                                                                    {
                                                                        category: this.getCategorydetails(trn.categoryId, trn.type, trn.userId)
                                                                    })
                                                            )
                                                            .map(trn => Object.assign(trn, this.getTitleIcon(trn)))
                                                            .reduce((red, trn, i, arr) => {
                                                                if (red.length > 0 && moment(arr[i - 1].paidTime).get('month') !== moment(trn.paidTime).get('month')) {
                                                                    red = red.concat(<div key={`month${trn._id}`} className='bill-grey-month'>
                                                                            {t(_months[moment(trn.paidTime).get('month')]) + (moment(trn.paidTime).get('year') !== moment().get('year') ? ' ' + moment(trn.paidTime).get('year') : '')}
                                                                        </div>);
                                                                }
                                                                return red.concat(
                                                                    <CollectionItem key={trn._id} className={i === this.state.currentTransactionIndex && this.state.transactionType === 'paid' && this.state.isOnSingleTransaction ? 'active-record' : ''}>
                                                                        <table
                                                                            className='no-padding UpcomingTable'
                                                                            style={{cursor: 'pointer'}}
                                                                            onClick={() => this.gotoSingleTransaction(trn, 'paid', i)}
                                                                        >
                                                                            <tbody>
                                                                            <tr>
                                                                                <td rowSpan='2' width="13%">
                                                                                    {trn.removeBackgroundColorCheck ? <img
                                                                                        src={trn.icon}
                                                                                        className='leftMargin10 circle'
                                                                                    /> : <img
                                                                                    src={trn.icon}
                                                                                    className='leftMargin10 circle'
                                                                                    style={{backgroundColor: trn.category.iconColor}}/>}
                                                                                    {
                                                                                        trn.hasPaid &&
                                                                                        <img
                                                                                            src={iconUrl + 'icon_done.png'}
                                                                                            className='circle bottom-check'
                                                                                        />
                                                                                    }
                                                                                </td>

                                                                                <td>
                                                                                    <div className='date-label'>
                                                                                        {trn.type==4 ? trn.title ? trn.title : trn.calcTitle : trn.calcTitle}
                                                                                    </div>
                                                                                </td>
                                                                                <td rowSpan='1'
                                                                                    className='right-align'>
                                                                                    <span className="nowrap amount-font-size">
                                                                                        <FormattedNumber
                                                                                            value={trn.amountPaid}
                                                                                            style='currency'
                                                                                            currency={currencyDetails.currencyCode || 'USD'}
                                                                                            minimumFractionDigits={2}
                                                                                            maximumFractionDigits={2}
                                                                                        />
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='grey-text'>
                                                                                    <Trans>PAID</Trans> {getShortDate(trn.paidTime, t)}
                                                                                </td>
                                                                                {trn.recurringServerId || trn.recurringIdLong || trn.recurringId ?
                                                                                    <td>
                                                                                        <div className='flex-box-recurring right'>
                                                                                            <Icon className='grey-text' right>refresh</Icon>
                                                                                            {!isCreator(trn.createdUserId, this.state.userSettings.userId) && localStorage.getItem(trn.createdUserId) ? this.props.auth &&this.props.auth.groupImageList && this.props.auth.groupImageList[trn.createdUserId] ? <img className='zero-margin height-20' src={this.props.auth.groupImageList[trn.createdUserId]}/>:<h5 className='zero-margin'>{JSON.parse(localStorage.getItem(trn.createdUserId)).ownerDetail.slice(0,1).toUpperCase()}</h5>:null}
                                                                                        </div>
                                                                                    </td> :  !isCreator(trn.createdUserId, this.state.userSettings.userId) && localStorage.getItem(trn.createdUserId)? <td>{this.props.auth &&this.props.auth.groupImageList && this.props.auth.groupImageList[trn.createdUserId] ? <img className='zero-margin height-20 right' src={this.props.auth.groupImageList[trn.createdUserId]}/>:<h5 className='zero-margin' style={{float: 'right'}}>{JSON.parse(localStorage.getItem(trn.createdUserId)).ownerDetail.slice(0,1).toUpperCase()}</h5>}</td>: null}
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </CollectionItem>
                                                                );
                                                            }, []) :
                                                        <CollectionItem>
                                                            <div className='date-label center-align grey-text'>
                                                                <Trans>NO_DATA_FOUND</Trans>
                                                            </div>
                                                        </CollectionItem>
                                                }
                                            </Collection>
                                        </InfiniteScroll>
                                    </div>
                                </Tabs.Tab>
                                <Tabs.Tab
                                    title={
                                        <div className='tab-title' title={t('OVERDUE')}>
                                            <Trans>OVERDUE</Trans>
                                            <br/>
                                            <b>
                                                <span className="nowrap">
                                                    <FormattedNumber
                                                        value={overdue}
                                                        style='currency'
                                                        currency={currencyDetails.currencyCode || 'USD'}
                                                        minimumFractionDigits={2}
                                                        maximumFractionDigits={2}
                                                        title={t('OVERDUE')}
                                                    />
                                                </span>
                                            </b>
                                        </div>
                                    }
                                    id='overdue'
                                >
                                    <div className='fix-height-tab bills-tab-height-top'>
                                        <InfiniteScroll
                                            pageStart={overduePageNumber}
                                            loadMore={this.loadOverdueTransactions}
                                            hasMore={hasMoreOverdueTransactions}
                                            loader={<div key={0}><Trans>LOADING</Trans>...</div>}
                                            useWindow={false}
                                            threshold={10}
                                        >
                                            <Collection>
                                                {
                                                    overdueTransactions.length ?
                                                        overdueTransactions
                                                            .map(trn =>
                                                                Object.assign(trn,
                                                                    {
                                                                        category: this.getCategorydetails(trn.categoryId, trn.type, trn.userId)
                                                                    })
                                                            )
                                                            .map(trn => Object.assign(trn, this.getTitleIcon(trn)))
                                                            .reduce((red, trn, i, arr) => {
                                                                if (red.length > 0 && moment(arr[i - 1].time).get('month') !== moment(trn.time).get('month')) {
                                                                    red = red.concat(<div key={`month${trn._id}`}
                                                                                          className='bill-grey-month'> {t(_months[moment(trn.time).get('month')]) + (moment(trn.time).get('year') !== moment().get('year') ? ' ' + moment(trn.time).get('year') : '')} </div>);
                                                                }
                                                                return red.concat(
                                                                    <CollectionItem key={trn._id} className={i === this.state.currentTransactionIndex && this.state.transactionType === 'overdue' && this.state.isOnSingleTransaction ? 'active-record' : ''}>
                                                                        <table
                                                                            className='no-padding UpcomingTable'
                                                                            style={{cursor: 'pointer'}}
                                                                            onClick={() => this.gotoSingleTransaction(trn, 'overdue', i)}
                                                                        >
                                                                            <tbody>
                                                                            <tr>
                                                                                <td rowSpan='2' width="13%">
                                                                                    {trn.removeBackgroundColorCheck ? <img
                                                                                        src={trn.icon}
                                                                                        className='leftMargin10 circle'
                                                                                    /> : <img
                                                                                    src={trn.icon}
                                                                                    className='leftMargin10 circle'
                                                                                    style={{backgroundColor: trn.category.iconColor}}/>}
                                                                                </td>
                                                                                <td>
                                                                                    <div className='date-label'>
                                                                                        {trn.type==4 ? trn.title ? trn.title : trn.calcTitle : trn.calcTitle}
                                                                                    </div>
                                                                                </td>
                                                                                <td rowSpan='1'
                                                                                    className='right-align'>
                                                                                    <span className="nowrap amount-font-size">
                                                                                        <FormattedNumber
                                                                                            value={trn.amount - trn.amountPaid}
                                                                                            style='currency'
                                                                                            currency={currencyDetails.currencyCode || 'USD'}
                                                                                            minimumFractionDigits={2}
                                                                                            maximumFractionDigits={2}
                                                                                        />
                                                                                    </span>
                                                                                </td>

                                                                            </tr>
                                                                            <tr>
                                                                                <td className='grey-text'>
                                                                                    <span className='red-text'>{getShortDate(trn.time, t)}</span>
                                                                                    {RIGHT_ARROW_ICON + ` `}
                                                                                    {getDayNegativeDiffFromToday(trn.time) === 0 ? (
                                                                                        <span>Due Yesterday</span>
                                                                                    ) : (
                                                                                        `${getDayNegativeDiffFromToday(trn.time)} `
                                                                                    )}
                                                                                    {getDayNegativeDiffFromToday(trn.time) !== 0 && <Trans>DAYS_PAST</Trans>}
                                                                                </td>
                                                                                {trn.recurringServerId || trn.recurringIdLong || trn.recurringId ? (
                                                                                    <td>
                                                                                        <div className='flex-box-recurring right'>
                                                                                            <Icon className='grey-text' right>refresh</Icon>
                                                                                            {!isCreator(trn.createdUserId, this.state.userSettings.userId) &&
                                                                                            localStorage.getItem(trn.createdUserId) ? (
                                                                                                this.props.auth &&
                                                                                                this.props.auth.groupImageList &&
                                                                                                this.props.auth.groupImageList[trn.createdUserId] ? (
                                                                                                    <img
                                                                                                        className='zero-margin height-20'
                                                                                                        src={this.props.auth.groupImageList[trn.createdUserId]}
                                                                                                    />
                                                                                                ) : (
                                                                                                    <h5 className='zero-margin'>
                                                                                                        {JSON.parse(localStorage.getItem(trn.createdUserId))
                                                                                                            .ownerDetail.slice(0, 1)
                                                                                                            .toUpperCase()}
                                                                                                    </h5>
                                                                                                )
                                                                                            ) : null}
                                                                                        </div>
                                                                                    </td>
                                                                                ) : !isCreator(trn.createdUserId, this.state.userSettings.userId) &&
                                                                                  localStorage.getItem(trn.createdUserId) ? (
                                                                                    <td>
                                                                                        {this.props.auth &&
                                                                                        this.props.auth.groupImageList &&
                                                                                        this.props.auth.groupImageList[trn.createdUserId] ? (
                                                                                            <img
                                                                                                className='zero-margin right height-20'
                                                                                                src={this.props.auth.groupImageList[trn.createdUserId]}
                                                                                            />
                                                                                        ) : (
                                                                                            <h5 className='zero-margin' style={{ float: 'right' }}>
                                                                                                {JSON.parse(localStorage.getItem(trn.createdUserId))
                                                                                                    .ownerDetail.slice(0, 1)
                                                                                                    .toUpperCase()}
                                                                                            </h5>
                                                                                        )}
                                                                                    </td>
                                                                                ) : null}
                                                                            </tr>

                                                                            </tbody>
                                                                        </table>
                                                                    </CollectionItem>
                                                                );
                                                            }, []) :
                                                        <CollectionItem>
                                                            <div className='date-label center-align grey-text'>
                                                                <Trans>NO_DATA_FOUND</Trans>
                                                            </div>
                                                        </CollectionItem>
                                                }

                                            </Collection>
                                        </InfiniteScroll>
                                    </div>
                                </Tabs.Tab>
                                <Tabs.Tab
                                    title={
                                        <div className='tab-title' title={t('UPCOMING')}>
                                            <Trans>UPCOMING</Trans>
                                            <br/>
                                            <b>
                                                <span className="nowrap">
                                                    <FormattedNumber
                                                        value={upcoming}
                                                        style='currency'
                                                        currency={currencyDetails.currencyCode || 'USD'}
                                                        minimumFractionDigits={2}
                                                        maximumFractionDigits={2}
                                                        title={t('UPCOMING')}
                                                    />
                                                </span>
                                            </b>
                                        </div>
                                    }
                                    id='upcoming'
                                >
                                    <div className='fix-height-tab bills-tab-height-top'>
                                        <InfiniteScroll
                                            pageStart={upcomingPageNumber}
                                            loadMore={this.loadUpcomingTransactions}
                                            hasMore={hasMoreUpcomingTransactions}
                                            loader={<div key={0}><Trans>LOADING</Trans>...</div>}
                                            useWindow={false}
                                            threshold={10}
                                        >
                                            <Collection>
                                                {
                                                    upcomingTransactions.length ?
                                                        upcomingTransactions
                                                            .map(trn =>
                                                                Object.assign(trn,
                                                                    {
                                                                        category: this.getCategorydetails(trn.categoryId, trn.type, trn.userId)
                                                                    })
                                                            )
                                                            .map(trn => Object.assign(trn, this.getTitleIcon(trn)))
                                                            .reduce((red, trn, i, arr) => {
                                                                if (i === 0 || moment(arr[i > 0 ? (i - 1) : 0].time).get('month') !== moment(trn.time).get('month')) {
                                                                    red = red.concat(<div key={`month${trn._id}`}
                                                                                          className='bill-grey-month'> {t(_months[moment(trn.time).get('month')]) + (moment(trn.time).get('year') !== moment().get('year') ? ' ' + moment(trn.time).get('year') : '')} </div>);
                                                                }
                                                                return red.concat(<CollectionItem key={trn._id} className={i === this.state.currentTransactionIndex && this.state.transactionType === 'upcoming' && this.state.isOnSingleTransaction ? 'active-record' : ''}>
                                                                    <table
                                                                        className='no-padding UpcomingTable'
                                                                        style={{cursor: 'pointer'}}
                                                                        onClick={() => this.gotoSingleTransaction(trn, 'upcoming', i)}
                                                                    >
                                                                        <tbody>
                                                                        <tr>
                                                                            <td rowSpan='2' width="13%">
                                                                                {trn.removeBackgroundColorCheck ? <img
                                                                                    src={trn.icon}
                                                                                    className='leftMargin10 circle'
                                                                                /> : <img
                                                                                src={trn.icon}
                                                                                className='leftMargin10 circle'
                                                                                style={{backgroundColor: trn.category.iconColor}}/>}
                                                                            </td>
                                                                            <td>
                                                                                <div className='date-label'>
                                                                                    {trn.type==4 ? trn.title ? trn.title : trn.calcTitle : trn.calcTitle}
                                                                                </div>
                                                                            </td>
                                                                            <td rowSpan='1' width='20%'
                                                                                className='right-align'>
                                                                                <span className="nowrap amount-font-size">
                                                                                    <FormattedNumber
                                                                                        value={trn.amount - trn.amountPaid}
                                                                                        style='currency'
                                                                                        currency={currencyDetails.currencyCode || 'USD'}
                                                                                        minimumFractionDigits={2}
                                                                                        maximumFractionDigits={2}
                                                                                    />
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
    <td className="grey-text">
        {`${getShortDate(trn.time, t)} ` + RIGHT_ARROW_ICON}
        {getDayDiffFromToday(trn.time) === 0 ? (
            <span style={{ color: "red" }}>Pay Now</span>
        ) : (
            `${getDayDiffFromToday(trn.time)} `
        )}
        <Trans>{getDayDiffFromToday(trn.time) === 0 ? '' : 'DAYS'}</Trans>
    </td>
    {trn.recurringServerId || trn.recurringIdLong || trn.recurringId ? (
        <td>
            <div className="flex-box-recurring right">
                <Icon className="grey-text" right>
                    refresh
                </Icon>
                {!isCreator(trn.createdUserId, this.state.userSettings.userId) &&
                localStorage.getItem(trn.createdUserId) ? (
                    this.props.auth &&
                    this.props.auth.groupImageList &&
                    this.props.auth.groupImageList[trn.createdUserId] ? (
                        <img
                            className="zero-margin height-20"
                            src={this.props.auth.groupImageList[trn.createdUserId]}
                        />
                    ) : (
                        <h5 className="zero-margin">
                            {JSON.parse(localStorage.getItem(trn.createdUserId)).ownerDetail
                                .slice(0, 1)
                                .toUpperCase()}
                        </h5>
                    )
                ) : null}
            </div>
        </td>
    ) : !isCreator(trn.createdUserId, this.state.userSettings.userId) &&
      localStorage.getItem(trn.createdUserId) ? (
        <td>
            {this.props.auth &&
            this.props.auth.groupImageList &&
            this.props.auth.groupImageList[trn.createdUserId] ? (
                <img
                    className="zero-margin height-20 right"
                    src={this.props.auth.groupImageList[trn.createdUserId]}
                />
            ) : (
                <h5 className="zero-margin" style={{ float: "right" }}>
                    {JSON.parse(localStorage.getItem(trn.createdUserId)).ownerDetail
                        .slice(0, 1)
                        .toUpperCase()}
                </h5>
            )}
        </td>
    ) : null}
</tr>


                                                                        </tbody>
                                                                    </table>
                                                                </CollectionItem>);
                                                            }, []) :
                                                        <CollectionItem>
                                                            <div className='date-label center-align grey-text'>
                                                                <Trans>NO_DATA_FOUND</Trans>
                                                            </div>
                                                        </CollectionItem>
                                                }
                                            </Collection>
                                        </InfiniteScroll>
                                    </div>
                                </Tabs.Tab>
                            </Tabs>
                            {/* <div className='add-budget-icon budget-modal'>
                                <Button
                                    onClick={this.openAddBillModel}
                                    floating
                                    large
                                    className="blue"
                                    waves="light"
                                    icon="add"
                                />
                            </div> */}
                        </Col>
                        <Col s={12} m={12} l={6}>
                            <div className={`Details-section mobile-no-view ${this.state.isOnSingleTransaction? 'detailView':''}`}>
                                <div className="col s12">

                                    <ul className="tabs z-depth-1 detail-tab">
                                        <li className="tab col">
                                            <div className="back-button" onClick={this.removeDetailDropdown}><i className="material-icons"> arrow_back </i> <span>Bills</span> <a>
                                                <Trans>DETAILS</Trans>
                                            </a></div>
                                            <a className="mobile-display-none">
                                                <Trans>DETAILS</Trans>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {
                                    (currentTransaction.category && this.state.isOnSingleTransaction) &&
                                    <div className='innerPage'>
                                        <div className='bill-tab-fixed floating-patch'>
                                        <table className='no-padding UpcomingTable bills-details-table'>
                                            <tbody>
                                            <tr>
                                                <td colSpan="3">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td className="image-relative" style={{verticalAlign: 'top'}}>
                                                    {currentTransaction.removeBackgroundColorCheck ? <img
                                                        src={currentTransaction.icon}
                                                        className='leftMargin10 circle'
                                                        style={{
                                                            height: '40px'
                                                        }}
                                                    />: <img
                                                    src={currentTransaction.icon}
                                                    className='leftMargin10 circle'
                                                    style={{
                                                        backgroundColor: currentTransaction.category.iconColor,
                                                        height: '40px'
                                                    }}
                                                />}
                                                </td>
                                                <td colSpan='2' style={{borderBottom: '1px solid #e5e6ea'}}>
                                                    <div className='date-label nowrap'>
                                                        {currentTransaction.calcTitle}
                                                    </div>
                                                    <div className='grey-text'>{currentTransaction.title}</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {
                                                        currentTransaction.hasPaid
                                                            ? <i className="white-text material-icons circle"
                                                                 style={{fontSize: '3rem', backgroundColor: '#91c653'}}>
                                                            done
                                                        </i>
                                                            : <i className="grey-text material-icons"
                                                                 style={{fontSize: '40px'}}>
                                                            {transactionType === 'recurring' ? 'refresh' : 'access_time'}
                                                        </i>
                                                    }
                                                </td>
                                                <td style={{
                                                    padding: '15px',
                                                    borderBottom: '1px solid #e5e6ea',
                                                    paddingLeft: '0'
                                                }}>
                                                    <div>
                                                        <div style={{fontSize: 'x-large', fontWeight: 'bold'}}>
                                                            <span className="nowrap">
                                                                <FormattedNumber
                                                                    value={currentTransaction.amount}

                                                                    style='currency'
                                                                    currency={currencyDetails.currencyCode || 'USD'}
                                                                    minimumFractionDigits={2}
                                                                    maximumFractionDigits={2}
                                                                />
                                                            </span>
                                                        </div>
                                                        {
                                                            transactionType === 'recurring'
                                                                ? <div className='grey-text'>
                                                                {
                                                                     completeRecurrenceDescription(currentTransaction, t)
                                                                }
                                                               
                                                            </div>
                                                                : <div className='grey-text'>
                                                                <Trans>BY</Trans> {getShortDateYr(currentTransaction.time, t)}
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                                <td style={{
                                                    padding: '15px',
                                                    borderBottom: '1px solid #e5e6ea',
                                                    'textAlign': 'center'
                                                }} className='grey-text'>
                                                    {
                                                        (transactionType === 'paid' && currentTransaction.hasPaid)
                                                            ? <div>
                                                            <div style={{fontSize: 'x-large', fontWeight: 'bold'}}>
                                                                {getShortDateYr(currentTransaction.paidTime, t)}
                                                            </div>
                                                            <div>
                                                                <Trans>PAID</Trans>
                                                            </div>
                                                        </div>
                                                            : (transactionType === 'recurring')
                                                            ? (!!currentTransaction.nextDueDate)
                                                                ? <div>
                                                                    <div style={{
                                                                        fontSize: 'x-large',
                                                                        fontWeight: 'bold'
                                                                    }}>
                                                                        {getShortDateYr(currentTransaction.nextDueDate, t)}
                                                                    </div>
                                                                    <div><Trans>NEXT_DUE</Trans></div>
                                                                </div>
                                                                : <div>
                                                                    <div style={{
                                                                        fontSize: 'x-large',
                                                                        fontWeight: 'bold'
                                                                    }}>
                                                                        <i className="grey-text material-icons"
                                                                           style={{fontSize: '3rem'}}>
                                                                            remove
                                                                        </i>
                                                                    </div>
                                                                </div>
                                                            : <div>
                                                                <div style={{fontSize: 'x-large', fontWeight: 'bold'}}>
                                                                    {Math.abs(getDayDiffFromToday(currentTransaction.time))}
                                                                </div>
                                                                {
                                                                    (transactionType === 'overdue')
                                                                        ? <div className='red-text'><Trans>PAST_DAYS</Trans></div>
                                                                        : (transactionType === 'paid')
                                                                        ? (currentTransaction.time > new Date().getTime())
                                                                            ? <div><Trans>DAY_TO_PAY</Trans></div>
                                                                            : <div className='red-text'><Trans>PAST_DAYS</Trans></div>
                                                                        : <div><Trans>DAY_TO_PAY</Trans></div>
                                                                }
                                                            </div>
                                                    }
                                                </td>
                                            </tr>
                                            {
                                                ((transactionType === 'paid' || transactionType === 'upcoming' || transactionType === 'overdue' )
                                                && currentTransaction.hasPaid !== true
                                                && currentTransaction.amountPaid > 0) &&
                                                <tr>
                                                    <td>
                                                        <i className="white-text material-icons circle"
                                                           style={{fontSize: '3rem', backgroundColor: '#91c653'}}>
                                                            done
                                                        </i>
                                                    </td>
                                                    <td colSpan="2"
                                                        style={{
                                                            padding: '0 15px 15px 15px',
                                                            borderBottom: '1px solid #e5e6ea',
                                                            paddingLeft: '0'
                                                        }}>
                                                        <div style={{padding: '10px', paddingLeft: '0'}}>
                                                            <div style={{
                                                                fontSize: 'x-large',
                                                                fontWeight: 'bold',
                                                                color: '#91c653'
                                                            }}>
                                                                <span className="nowrap">
                                                                    <FormattedNumber
                                                                        value={currentTransaction.amountPaid}
                                                                        style='currency'
                                                                        currency={currencyDetails.currencyCode || 'USD'}
                                                                        minimumFractionDigits={2}
                                                                        maximumFractionDigits={2}
                                                                    />
                                                                </span><Trans>PAID</Trans>
                                                            </div>
                                                            <div 
                                                            style={{
                                                                fontSize: 'x-large',
                                                                fontWeight: 'bold',
                                                                color: '#f44336'
                                                            }}>
                                                                <span className="nowrap">
                                                                    <FormattedNumber
                                                                        value={currentTransaction.amount - currentTransaction.amountPaid}
                                                                        style='currency'
                                                                        currency={currencyDetails.currencyCode || 'USD'}
                                                                        minimumFractionDigits={2}
                                                                        maximumFractionDigits={2}
                                                                    />
                                                                </span><Trans>DUE_NOW</Trans>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                            {currentTransaction.notes ?
                                            <tr>
                                                <td>
                                                    <i className="grey-text material-icons" style={{fontSize: '40px'}}>
                                                        comments
                                                    </i>
                                                </td>
                                                <td colSpan='2' style={{
                                                    'padding': '15px 15px 15px 0px',
                                                    'borderBottom': '1px solid rgb(229, 230, 234)'
                                                }}>
                                                    <div className="grey-text">{currentTransaction.notes}</div>
                                                </td>
                                            </tr> : null }
                                            {currentTransaction.localIdLong && currentTransaction.amountPaid > 0 ? <tr >
                                                <td></td>
                                                <td colSpan='2'><p className='margin-bottom-0 font-size-1point2-rem'><Trans>PAYMENTS</Trans></p></td>
                                                </tr>:null}
                                            {currentTransaction.localIdLong && currentTransaction.amountPaid > 0 ? <tr style={{
                                                'paddingBottom': '15px'}}>
                                                    <td></td>
                                                    <td style={{
                                                'borderBottom': '1px solid rgb(229, 230, 234)'
                                                }} colSpan='2'>{this.getPaymentsDiv()}</td>
                                                </tr>:null}

                                                {
                                                    currentTransaction && currentTransaction.image ?
                                                        <tr>
                                                    <td />
                                                    <td style={{
                                                        'padding': '15px 15px 15px 0px',
                                                        'borderBottom': '1px solid rgb(229, 230, 234)'
                                                    }}>
                                                        <div className="expense-image">
                                                            {/* <Loader condition={this.props.expenses.EXPENSE_IMAGE_LOADING}/> */}
                                                            {
                                                                currentTransaction && currentTransaction.image ?
                                                                    <img style={{width: '35%'}} src={`${umediaUrl}media/${currentTransaction.createdUserId}/${currentTransaction.image}`} alt="not found" /> : null
                                                            }
                                                        </div>
                                                    </td>
                                                    <td style={{
                                                        'padding': '15px 15px 15px 0px',
                                                        'borderBottom': '1px solid rgb(229, 230, 234)'
                                                    }} />
                                                </tr> : null }
                                            
                                            {!currentTransaction.hasPaid && transactionType !== 'recurring' && (isCreator(currentTransaction.createdUserId, this.state.userSettings.userId) || currentTransaction.autoPaid || currentTransaction.serviceProviderId)? <tr>
                                                <td />
                                                <td colSpan={2} style={{
                                                    'padding': '15px 15px 15px 0px',
                                                    'borderBottom': '1px solid rgb(229, 230, 234)'
                                                }}>
                                                    <div style={{display: 'flex', flex: 1}}>
                                                        {!isCreator(currentTransaction.createdUserId, this.state.userSettings.userId) ? null : <div className='add-icon full-icon-btn' onClick={this.openMarkForm}>
                                                            <Button flat waves="light"
                                                                    type="button" name="mark" className="wave-btn">
                                                                <i className="material-icons bill-icon">check</i>
                                                            </Button>
                                                            <h6 htmlFor="mark" style={{textAlign: 'center'}}>
                                                                <Trans>MARK_PAID</Trans>
                                                            </h6>
                                                        </div>}
                                                        {this.getAutoPaidDiv()}
                                                        {this.getServiceProviderObj()}
                                                    </div>
                                                </td>
                                            </tr> : null}
                                            {/* {isCreator(currentTransaction.createdUserId, this.state.userSettings.userId) ? <tr>
                                                <td />
                                                <td style={{
                                                    'padding': '15px 15px 15px 0px',
                                                    'borderBottom': '1px solid rgb(229, 230, 234)'
                                                }}>
                                                    <div className='add-icon'>
                                                        <button
                                                            className="btn waves-effect waves-light light-blue accent-4 p-2"
                                                            onClick={this.openEditBillModel}
                                                            type="button" name="action">
                                                            <Trans>EDIT</Trans>
                                                            <i className="material-icons right">edit</i>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td style={{
                                                    'padding': '15px 15px 15px 0px',
                                                    'borderBottom': '1px solid rgb(229, 230, 234)'
                                                }}>
                                                    <button className="btn waves-effect waves-light red lighten-2 accent-4"
                                                            type="button" name="action"
                                                            onClick={this.onDeleteBills.bind(this)}><Trans>DELETE</Trans>
                                                        <i className="material-icons right">delete</i>
                                                    </button>
                                                </td>
                                            </tr>: null} */}
                                            {/*appends the Created user info div*/}
                                            {!isCreator(currentTransaction.createdUserId, this.state.userSettings.userId) && localStorage.getItem(currentTransaction.createdUserId) ? <tr>
                                                <td>{this.props.auth && this.props.auth.groupImageList && this.props.auth.groupImageList[currentTransaction.createdUserId] ? <img style={{
                                                    width: '40px',
                                                    height: '40px',
                                                    borderRadius: '100%',
                                                    marginTop: '8px',
                                                    marginRight: '2px',
                                                    paddingLeft: '0'
                                                }} src={this.props.auth.groupImageList[currentTransaction.createdUserId]}/>:<h5 className='zero-margin-detail'>{JSON.parse(localStorage.getItem(currentTransaction.createdUserId)).ownerDetail.slice(0,1).toUpperCase()}</h5>}</td>
                                                <td colSpan={2} style={{
                                                    'padding': '15px 15px 15px 0px',
                                                    'borderBottom': '1px solid rgb(229, 230, 234)'
                                                }}><div style={{display: 'flex', flex: 1}}>{this.getCreatedUserInfoDiv()}</div></td></tr> : null}


                                            <tr>
                                                <td />
                                                <td colSpan='2' className='grey-text'>
                                                    <Trans>CREATED</Trans>&nbsp;{getDateTimeFormat(currentTransaction.created_at)}<br/>
                                                    <Trans>UPDATED</Trans>&nbsp;{getDateTimeFormat(currentTransaction.updated_at)}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                <Modal
                    header={!this.state.addNewBill ? t('EDIT_BILL') : t('ADD_BILL')}
                    id="addBillModel"
                    open={this.state.openBillModel}
                    actions={
                        <div>
                            <Button flat modal="close" waves="light" onClick={this.closeBillsModel}><Trans>CANCEL</Trans></Button>
                        </div>
                    }
                >
                    {this.state.openBillModel ? <BillsForm transaction={{}}
                               currentActiveTab={this.state.currentActiveTab}
                               addNewBill={this.state.addNewBill}
                               disableRecurrence={this.state.editSingleRecord}
                               disableCategory={this.state.editSingleRecord}
                               billsData={this.state.currentTransaction}
                               dataLoading={BILL_DATA_LOADING}
                               onSubmit={this.addUpdateBills}/> : null}
                </Modal>

                {/*mark model*/}
                <Modal
                    header={t('MARK_PAID')}
                    id="markBillModel"
                    open={this.state.openMarkModel}
                    actions={
                        <div>
                            <Button flat modal="close" waves="light" onClick={this.closeBillsModel}>
                                <Trans>CANCEL</Trans>
                            </Button>
                        </div>
                    }
                >
                    <MarkBill billsData={this.state.currentTransaction}
                              reOpenModel={this.reOpenMarkModel}
                              currencyDetails={currencyDetails}
                              transactionType={this.state.transactionType}
                              onSubmit={this.setMarkData}/>
                </Modal>
                <Loader condition={
                    PAID_BILLING_DATA_LOADING ||
                    UPCOMING_BILLING_DATA_LOADING ||
                    OVERDUE_BILLING_DATA_LOADING ||
                    RECURRING_BILLING_DATA_LOADING ||
                    BILL_DATA_LOADING
                }/>
            </div>
        );
    }
}

Bills.propTypes = {
    getPaidBills: PropTypes.func.isRequired,
    getOverdueBills: PropTypes.func.isRequired,
    getUpcomingBills: PropTypes.func.isRequired,
    getRecurringBills: PropTypes.func.isRequired,
    getRecurringDataById: PropTypes.func.isRequired,
    getBillingStats: PropTypes.func.isRequired,
    getCategories: PropTypes.func.isRequired,
    getServiceProviders: PropTypes.func.isRequired,
    deleteTransaction: PropTypes.func.isRequired,
    addUpdateBill: PropTypes.func.isRequired,
    markBillPaid: PropTypes.func.isRequired,
    updateBillsList: PropTypes.func.isRequired,
    fetchBillPaymentDetails: PropTypes.func.isRequired,
    getSingleServiceProvider: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return state;
};

export default withTranslation() (connect(
    mapStateToProps,
    {
        updateBillsList,
        getBillingStats,
        getPaidBills,
        getOverdueBills,
        getUpcomingBills,
        getRecurringBills,
        getCategories,
        getServiceProviders,
        deleteTransaction,
        getRecurringDataById,
        addUpdateBill,
        markBillPaid,
        fetchBillPaymentDetails,
        getSingleServiceProvider
    })(withRouter(Bills)));
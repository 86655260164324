import React, {Component} from 'react';
import {withTranslation, Trans} from 'react-i18next';
import {
    Row,
    Col,
    Collection,
    CollectionItem,
    Button,
    Modal, Icon
} from 'react-materialize';
import Tabs from "./common/Tabs";
import {unescape} from 'lodash';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {FormattedNumber} from "react-intl";
import {
    LONG_MONTHS as _months,
    AGGREGATOR_CONNECTION_STATUS as aggregatorStatus
} from './common/ConstData';
import InfiniteScroll from 'react-infinite-scroller';
import {
    getShortDate,
    sortArray,
    getServiceProvider, getAccountTypeName, getAccountTypeById,
    getAccountInitialBalanceLabelById,
    getAccountBalanceLabelById, getCategoryById, finObjectByKey, sortArrayDesc, getServiceProviderName,
    getServiceLogoUrl,
    isNegativeAccountBalance,
    sortAccordingToType,
    checkNatureOfAccountTransaction,isOwnerAccount,isOfflineAccount
} from './common/Methods';
import CTooltip, {CTooltipHtml} from './common/ToolTip';

import {
    getAccountList,
    addUpdateAccount,
    getAccountTrxList,
    deleteAccountData,
    updateAccTrx,
    deleteAccountTransaction, deleteIncomeTrx
} from "../actions/accountActions";
import {getCurrencies} from "../actions/authActions";
import Loader from './Loader';
import AccountForm from './AddEditAccount';
import Swal from 'sweetalert2';
import moment from "moment";
import {CAT_TYPE, CAT_TYPE_TEXT, iconUrl, RIGHT_ARROW_ICON, s3Url, umediaUrl} from "../actions/config";
import IncomeDetail from "./IncomeDetail";
import { unstable_renderSubtreeIntoContainer } from 'react-dom';

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentActiveTab: 'accList',
            acctTrxList: [],
            selectedAccount: {},
            currentTransaction: {},
            currentDayTransactions: [],
            userSettings: JSON.parse(localStorage.getItem('profileDetails')) || {},
            openEditModal: false,
            editableAccountData: null,
            hasMoreTransactions: true,
            transactionPageNumber: 0,
            loadMoreTrx: false
        };
    }

    componentDidMount() {
        this.props.getAccountList();
        if(!(this.props.auth && this.props.auth.currencies)) {
            this.props.getCurrencies();
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.account.GET_ACCOUNT_LIST_FAILED) {
            this.getAlertBox(nextProps.account.GET_ACCOUNT_LIST_FAILED.message, 'error');
        }

        if(this.selectedAccount){
            this.gotoTransaction(this.selectedAccount,'', nextProps);
        }
    }

    getAlertBox = (message, type) => {
        const t=this.props.t;
        Swal.fire({
            title:t(message),
            position: 'bottom-start',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
            background: type==='success' ? '#8dc44e' : '#f1521c',
            customClass: {
                title:'sweet-alert',
                container: 'sweet-alert-container'
            }
        });
    };

    gotoTransaction = (acct, msg = '',nextProps) => {
        (nextProps || this.props).getAccountTrxList(acct.accountId, ({results = []}) => {
            if(msg === 'delete') {
                this.getAlertBox('ENTRY_DELETED', 'success');
            }
            this.setState({
                currentActiveTab: 'transactionList',
                acctTrxList: results,
                currentDayTransactions: {},
                selectedAccount: acct,
                loadMoreTrx: false,
                hasMoreTransactions: true,
                transactionPageNumber: 0
            });
        })
    };

    gotoSingleTransaction = (currentTransaction, i,currentAct) => {
        currentTransaction.selectedAccount = currentAct;
        this.setState({
            currentDayTransactions: {}
        }, e=> this.setState({currentDayTransactions: currentTransaction}));
    };

    updateAccountTrx = data => {
        // this.state.selectedAccount.accountId,
        const trns = this.state.currentDayTransactions;
        this.props.updateAccTrx(data, trns.type, this.state.selectedAccount.accountId, (accData, msg, res, updateAcc) => {
            if(res) {
                const trxObj = {
                    ...finObjectByKey(accData, this.state.currentDayTransactions._id, '_id'),
                    category: getCategoryById(trns.categoryId, trns.type)
                };

                this.setState({
                    acctTrxList: accData,
                    currentDayTransactions: trxObj && trxObj._id ? trxObj : {},
                    selectedAccount: updateAcc && Object.keys(updateAcc).length > 0 ? updateAcc : this.state.selectedAccount
                });
                this.getAlertBox(msg, 'success');
            }
        }, this.state.transactionPageNumber)
    };

    refectorSelectedAcct = (acct) => {
        return {
            ...this.state.selectedAccount,
            accountBalance: acct.accountBalance,
            accountId: acct.accountId,
            currentBalance: this.state.selectedAccount.currentBalance+(+acct.accountBalance - this.state.selectedAccount.accountBalance),
            accountName: acct.accountName,
            accountType: acct.accountType,
            serviceProviderId: acct.serviceProviderId
        }
    };

    addUpdateAccount = (data) => {
        this.props.addUpdateAccount(data, (msg) => {
            this.getAlertBox(msg, 'success');
            const dataObj = data.accounts[0];
            const selectedAccount = this.refectorSelectedAcct(dataObj);
            if(data.accounts[0]._id) {
                this.setState({
                    selectedAccount: selectedAccount
                });
            }
            this.closeAccountEditModel();
        })
    };

    showAccountEditModal = async (data) => {
        await this.setState({
            openEditModal: false
        });
        this.setState({
            editableAccountData: data,
            openEditModal: true
        })
    }

    showDeleteAccountDialog = (data) => {
        const t = this.props.t;
        Swal.fire({
            title: t('Delete?'),
            text:t('ACCOUNT_CONFIRM_DELETE'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#b8b8b8',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.value) {
                this.props.deleteAccountData(data, (msg) => {
                    this.getAlertBox(msg, 'success');
                    this.setState({
                        editableAccountData: null,
                        openEditModal: false,
                        selectedAccount: {},
                        currentActiveTab: 'accList'
                    })
                });
            }
        });
    }

    closeAccountEditModel = () => {
        this.setState({
            editableAccountData: null,
            openEditModal: false
        })
    }

    setActiveTab = (tab) => {
        this.setState({
            currentActiveTab: tab.id
        });
    };
    fetchGroupAccountBalance = (arr=[],defaultCurrencyCode) =>{
        let total=0;
        //console.log("currencyDetails ->>",defaultCurrencyCode)
        arr.forEach(e=>{
            if(e.currencyCode==undefined || e.currencyCode.length==0 || e.currencyCode==defaultCurrencyCode){
                if(e.includeBalance !== false){
                    total+=e.currentBalance || 0
                }              
            }
        })
        return total;
    }
    isAccountInError = (data)=>{
       return (data.aggregatorStatus==aggregatorStatus.MANUAL_DISCONNECTED)?true:false
    }
    isAccountDisconnected = (data)=>{
       let accountConfirmed = false;
       if(data.accountConfirmed!==undefined && data.accountConfirmed!=null && data.accountConfirmed==true){
            accountConfirmed = true;
       } 
       let aggregatorStatusOtherThanZero =false;
       if(data.aggregatorStatus!==undefined && data.aggregatorStatus!=0){
            aggregatorStatusOtherThanZero = true;
       }
       return ( !accountConfirmed || aggregatorStatusOtherThanZero)
     }

    showAccountDetail = (data) => {
        let serviceData = getServiceProvider(data.serviceProviderId);

        if(!serviceData || !data.serviceProviderId) {
            serviceData = getAccountTypeById(data.accountType);
            serviceData.logoURL = serviceData.icon;
            serviceData.providerName = serviceData.NAME;
        }
        if(!serviceData)
            return null;
        serviceData.balanceLabel = getAccountBalanceLabelById(data.accountType);
        const {userSettings: {currencyDetails}} = this.state;
      return (
          <tr className={'border-bottom-1'}>
              <td width='7%' style={{verticalAlign: 'top'}}>
                {data.imageName ? (
                        <img
                            src={umediaUrl + `media/${data.userId}/${data.imageName}`}
                            alt='icon'
                            className='leftMargin10 circle'
                            style={{
                                backgroundColor: serviceData.iconColor,
                                height: '54px'
                            }}
                        />
                    ) : (
                        <img
                            src={s3Url + `${serviceData.logoURL}.png`}
                            alt='icon'
                            className='leftMargin10 circle'
                            style={{
                                backgroundColor: serviceData.iconColor,
                                height: '54px'
                            }}
                        />
                    )}
                   {
                    data.onlineAccount
                    ? (
                        <div style={{marginLeft: '20px', width: '10px'}}>
                        {
                            this.isAccountInError(data)
                                ? <img src={iconUrl + 'icon_sync_disabled.png'} alt="Account" />
                                :  this.isAccountDisconnected(data)
                                    ? <img src={iconUrl + 'icon_sync_disabled.png'} alt="Account" />
                                    : <img src={iconUrl + 'icon_sync_green.png'} alt="Account" />
                        }
                        </div>
                    )
                    : null
                    }

               
              </td>
              <td colSpan='2'>
                  <div className='date-label'>
                      {unescape(serviceData.providerName)}
                  </div>
                  <div className="grey-text">
                      {(getAccountTypeName(data.accountType)) + (data.accountName ? (RIGHT_ARROW_ICON + data.accountName + RIGHT_ARROW_ICON +data.accountNumber) : '')}
                  </div>
              </td>
              <td colSpan='2'>
                  <div className='date-label min-width-70 right-align'>
                      <span className={'account-balance nowrap ' + (isNegativeAccountBalance(data) ? 'red-text' : '')}>
                           <FormattedNumber
                               value= {data.currentBalance}
                               style='currency'
                               currency={data.currencyCode || currencyDetails.currencyCode || 'USD'}
                               minimumFractionDigits={2}
                               maximumFractionDigits={2}
                           />
                      </span>
                  </div>
                  <div className="grey-text align-right">
                      <span className={'empty-span-accounts'}> </span>
                  </div>
              </td>
          </tr>
      )
    };

    deleteTransaction = () => {
        const t = this.props.t;
        const expenseId = {transactions: [{_id: this.state.currentDayTransactions._id}]};
        Swal.fire({
            title: t('Delete?'),
            text:t("Are you sure you want to delete this entry?"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#b8b8b8',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.value) {
                this.props.deleteAccountTransaction(expenseId, this.state.selectedAccount, (updateAcc, msg) => {
                    this.gotoTransaction(updateAcc, msg);
                });
            }
        });
    };

    deleteIncomeTrx = () => {
        let incomeId = {
            id: {_id: this.state.currentDayTransactions._id, type: 2}
        };

        const { t } = this.props;
        const currentTransaction = this.state.currentDayTransactions;
        let deleteMessage = t('BILL_DELETE_MESSAGE_1');
        let singleRecord = true;
        if(currentTransaction.recurringServerId || currentTransaction.recurringIdLong || currentTransaction.recurringId) {
            deleteMessage = t('DELETE_INCOME_MESSAGE');
            singleRecord = false;
        }

        Swal.fire({
            title: t('DELETE'),
            text: deleteMessage,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: singleRecord ? '#b8b8b8' : '#d33',
            confirmButtonText: singleRecord ? t('DELETE') : t('EDIT_ALL_FUTURE'),
            cancelButtonText: singleRecord ? t('CANCEL') : t('DELETE_THIS')
        }).then((result) => {
            if (result.value) {
                if(currentTransaction.recurringServerId || currentTransaction.recurringIdLong || currentTransaction.recurringId) {
                    if(currentTransaction.recurringIdLong) {
                        incomeId = {
                            recurringIdLong: currentTransaction.recurringIdLong,
                            type: 2,
							startTime: currentTransaction.time
                        };
                    }else if(currentTransaction.recurringServerId) {
                        incomeId = {
                            recurringServerId: currentTransaction.recurringServerId,
                            type: 2,
							startTime: currentTransaction.time
                        };
                    }else if(currentTransaction.recurringId) {
                        incomeId = {
                            recurringId: currentTransaction.recurringId,
                            type: 4,
                            deviceId: currentTransaction.deviceId
                        };
                    }
                }
                this.props.deleteIncomeTrx(incomeId, this.state.selectedAccount, (updateAcc, msg) => {
                    this.gotoTransaction(updateAcc, msg);
                }, singleRecord);
            }else  if(result.dismiss === 'cancel'){
                if(!singleRecord) {
                    this.props.deleteIncomeTrx(incomeId, this.state.selectedAccount, (updateAcc, msg) => {
                        this.gotoTransaction(updateAcc, msg);
                    }, !singleRecord);
                }
            }
        });
    };

    loadTransactions = () => {
        if(this.state.loadMoreTrx === false && Object.keys(this.state.selectedAccount).length > 0) {
            this.setState({
                loadMoreTrx: true
            });
            this.props.getAccountTrxList(this.state.selectedAccount.accountId, ({results = []}) => {
                let resultList = this.state.acctTrxList.concat(results);
               this.setState({
                   loadMoreTrx: false,
                   acctTrxList: resultList,
                   hasMoreTransactions: results.length !== 0,
                   transactionPageNumber: this.state.transactionPageNumber + 1
               })
            }, this.state.transactionPageNumber + 1);
        }
    };


    getTitleIcon = (trn) => {
        let calcTitle = '', icon;
        calcTitle = getServiceProviderName(trn);
        icon = getServiceLogoUrl(trn);
        return {calcTitle, icon};
    };

    render() {
        const {
            acctTrxList = [],
            selectedAccount = {},
            userSettings: {currencyDetails},
            hasMoreTransactions,
            transactionPageNumber
        } = this.state;
        const {
            t,
            account: {
                GET_ACCOUNT_LIST_LOADING,
                accList = []
            }
        } = this.props;
        const accountObject=sortAccordingToType(accList||{});


        return (
			<div>
				<div>
					<Row className='mb-0 mobile-line-height'>
						<Col s={12} m={12} l={12} style={{lineHeight: '30px'}}>
							&nbsp;
						</Col>
					</Row>
					<Row className='mb-0'>
						<Col s={12} m={12} l={6} style={{position: 'relative'}}>
							<Col s={12} m={12} l={12}  className='tab-mobile-fixed'>
								<Tabs
									reciveProps={true}
									activeTab={{
                                        id: this.state.currentActiveTab
                                    }}
									className='tab-demo z-depth-1'
									onClick={this.setActiveTab}
								>
									<Tabs.Tab
										title={
											<div className='tab-title' title={t('ACCOUNT_DETAIL')}>
												<Trans>ACCOUNT_DETAIL</Trans>
											</div>
                                        }
										id="accList"

									>
										<Collection
											className='fix-height-tab mobile-height'>
                                            {
                                                accountObject ?
                                                Object.keys(accountObject).map((obj, i) => {
                                                    return (
                                                        <>
                                                        {accountObject[obj].length > 0  && <Row className='account-group-label-div margin-bottom-10'>
                                                            <Col s={6} m={6} l={6}>
                                                            <h5 className={'margin-0'}><Trans>{obj.toUpperCase()}</Trans></h5>
                                                            </Col>
                                                            <Col  s={6} m={6} l={6}>
                                                                <div className='right-align floatRight date-label min-width-100 account-group-label-balance'>
                                                                    <span className='account-group-label-balance'>
                                                                        <FormattedNumber
                                                                            value={this.fetchGroupAccountBalance(accountObject[obj],currencyDetails.currencyCode)|| 0}
                                                                            style='currency'
                                                                            currency={currencyDetails.currencyCode || 'USD'}
                                                                            minimumFractionDigits={2}
                                                                            maximumFractionDigits={2}
                                                                        />
                                                                        </span></div>
                                                            </Col>
                                                        </Row>}
                                                    {accountObject[obj].length > 0 && accountObject[obj].map(item=>{
                                                            return(
                                                                <React.Fragment key={item._id || i}> {item.accountId ?
                                                                    <CollectionItem className={'border-bottom-0'}>
                                                                        <table
                                                                            className='no-padding'
                                                                            onClick={() => this.gotoTransaction(item)}
                                                                            style={{cursor: 'pointer'}}
                                                                        >
                                                                            <tbody>
                                                                            {this.showAccountDetail(item)}
                                                                            </tbody>
                                                                        </table>
                                                                    </CollectionItem> : null
                                                                   }
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                        </>
                                                    )}):
													<CollectionItem>
														<div className='center-align grey-text no-data-label'>
															<Trans>NO_ACCOUNTS</Trans>
														</div>
													</CollectionItem>
                                            }
										</Collection>
									</Tabs.Tab>
									<Tabs.Tab
										title={
											<div className='tab-title' title={t('TRAN_LIST')}>
												<Trans>TRAN_LIST</Trans>
											</div>
                                        }
										id="transactionList"
									>
                                        <Row className='mb-0 month-selector'>
                                            { accList.length > 0 && Object.keys(selectedAccount).length > 0 ?
                                                <>
                                                    <Col s={8} m={8} l={8} style={{position: 'relative'}}>
                                                        <div className='year-selector' style={{paddingTop: '13px'}}>
                                                            {
                                                                selectedAccount.imageName ? (
                                                                    <img
                                                                        src={umediaUrl + `media/${selectedAccount.userId}/${selectedAccount.imageName}`}
                                                                        alt='icon'
                                                                        className='leftMargin10 circle'
                                                                        style={{
                                                                            // backgroundColor: serviceData.iconColor,
                                                                            height: '54px'
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={s3Url + getServiceLogoUrl(selectedAccount) + '.png'}
                                                                        alt='icon'
                                                                        className='leftMargin10 circle'
                                                                        style={{
                                                                            height: '54px'
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                            <div className="value">
                                                                <p className="title">{getServiceProviderName(selectedAccount)}</p>
                                                                <div className="balance-label grey-text">
                                                                    <p>
                                                                        <span>{getAccountInitialBalanceLabelById(selectedAccount.accountType)}</span>&nbsp;
                                                                        <FormattedNumber
                                                                            value={selectedAccount.accountBalance || 0}
                                                                            style='currency'
                                                                            currency={selectedAccount.currencyCode || currencyDetails.currencyCode || 'USD'}
                                                                            minimumFractionDigits={2}
                                                                            maximumFractionDigits={2}
                                                                        />
                                                                    </p>
                                                                </div>
                                                            </div>{(false && isOwnerAccount(selectedAccount) && (isOfflineAccount(selectedAccount))) ? (<>
                                                                <i className="material-icons blue-text height-30"
                                                                onClick={() =>false? this.showAccountEditModal(selectedAccount): this.getAlertBox(t('THIS_FEATURE_IS_NOT_AVAIL_IN_WEB_FOR_NOW'), 'warning')}>
                                                                    edit
                                                                </i>
                                                                <i className="material-icons red-text height-30"
                                                                onClick={() => false? this.showDeleteAccountDialog(selectedAccount): this.getAlertBox(t('THIS_FEATURE_IS_NOT_AVAIL_IN_WEB_FOR_NOW'), 'warning')}>
                                                                    delete
                                                                </i>
                                                                </>) : null
                                                            }   
                                                        </div>
                                                    </Col>
                                                    <Col s={4} m={4} l={4} className="total-price">
                                                        <div  data-tip data-for="providerNameHint">
                                                        <span className="nowrap">
                                                            <div className='display-flex cursor-help width-fit-content justify-content-flex-end'>
                                                                
                                                                <FormattedNumber
                                                                value={selectedAccount.currentBalance || 0}
                                                                style='currency'
                                                                currency={selectedAccount.currencyCode || currencyDetails.currencyCode || 'USD'}
                                                                minimumFractionDigits={2}
                                                                maximumFractionDigits={2}
                                                            />
                                                            </div>
                                                            <div className='timelableinaccoutn' >
                                                                
                                                            { (isOfflineAccount(selectedAccount)) ? (<>
                                                                <CTooltipHtml id="providerNameHint" content={
                                                                    <ul className='tolltipconent'>

                                                                    <li><Icon className="material-icons">label</Icon> {<Trans>ACCOUNT_BALANCE_TOOLTIP</Trans>}</li>
                                                                    {/* <li><Icon className="material-icons">label</Icon> {<Trans>ACCOUNT_BALANCE_TOOLTIP_2</Trans>}</li> */}
                                                                    
                                                                    </ul>}/>
                                                                <Icon className="material-icons margin-right-5">info_outline</Icon>
                                                                </>) : null
                                                            } 
                                                            </div>
                                                          
                                                        </span>
                                                       
                                                        </div>
                                                        <div style={{display:'inline-block', fontSize: '11px'}}>{<Trans>LAST_UPDATED_AT</Trans>}  {moment(selectedAccount.lastModifyTime).format('MMM DD YYYY hh:mm a')}
                                                                </div>
                                                        </Col>
                                                </> : null
                                            }
                                        </Row>
                                        <Collection className='fix-height-tab bill-tab-fixed fix-height-tab-account'>
                                            <InfiniteScroll
                                                pageStart={transactionPageNumber}
                                                loadMore={this.loadTransactions}
                                                hasMore={hasMoreTransactions}
                                                useWindow={false}
                                                threshold={1}
                                            >
                                                <div>
                                                    {
                                                        acctTrxList.length ?
                                                            acctTrxList.map(trns => Object.assign(trns, this.getTitleIcon(trns)))
                                                                .reduce((red, trns, i, arr) => {
                                                                    if (i === 0 || moment(arr[i > 0 ? (i - 1) : 0].time).get('month') !== moment(trns.time).get('month')) {
                                                                        red = red.concat(<div key={`month${trns._id}`}
                                                                                              className='bill-grey-month'> {t(_months[moment(trns.time).get('month')]) + (moment(trns.time).get('year') !== moment().get('year') ? ' ' + moment(trns.time).get('year') : '')} </div>);
                                                                    }
                                                                    trns.category = getCategoryById(trns.categoryId, trns.type) || {};
                                                                    return red.concat(
                                                                        <CollectionItem key={trns._id}
                                                                                        className={trns._id === this.state.currentDayTransactions._id ? 'active-record' : ''}
                                                                        >
                                                                            <table
                                                                                className='no-padding table-align'
                                                                                style={{cursor: 'pointer'}}
                                                                                onClick={() => this.gotoSingleTransaction(trns, i,this.state.selectedAccount)}
                                                                            >
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td rowSpan='2'>
                                                                                        <img
                                                                                            src={trns.merchantId && trns.merchantIcon ? trns.merchantIcon : iconUrl + (trns.category || {}).iconUrl + '.png'}
                                                                                            alt='icon'
                                                                                            className='leftMargin10 circle'
                                                                                            style={{backgroundColor: (trns.category || {}).iconColor, height: '54px'}}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className='date-label'>
                                                                                            {t(CAT_TYPE[trns.isTransfer ? (trns.type + 't') : (trns.type + '')])} {RIGHT_ARROW_ICON} {getShortDate(trns.time, t)}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className='right-align'>
                                                                                            <span className="nowrap">
                                                                                            {checkNatureOfAccountTransaction(trns)? <span className='amount-symbol'>- </span>: <span className='amount-symbol'>+ </span>}
                                                                                                <FormattedNumber
                                                                                                    value={trns.amount}
                                                                                                    style='currency'
                                                                                                    currency={trns.currencyCode || currencyDetails.currencyCode || 'USD'}
                                                                                                    minimumFractionDigits={2}
                                                                                                    maximumFractionDigits={2}
                                                                                                />
                                                                                            </span>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan={trns.recurringServerId || trns.recurringIdLong || trns.recurringId || moment().endOf('day') <trns.time ? '1':'2'} className='categories accountDetailCategories'>
                                                                                        <div className="grey-text break-text">
                                                                                            {((trns.category || {}).name ? (unescape((trns.category || {}).name) + RIGHT_ARROW_ICON) : '') + (trns.notes || '')}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {trns.aggregatorStatus === 'PENDING' || trns.updateBalance === true ? <span className='pendinglable'><Trans>PENDING</Trans></span>: null}
                                                                                        {trns.recurringServerId || trns.recurringIdLong || trns.recurringId ? <Icon right>refresh</Icon>: null}
                                                                                        {moment().endOf('day')<trns.time ?<div className="future-label-account break-text"><p><Trans>FUTURE</Trans></p></div>:null}
                                                                                    </td> 
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </CollectionItem>
                                                                    )
                                                                }, []) :
                                                            <CollectionItem>
                                                                <div className='center-align grey-text no-data-label'>
                                                                    <Trans>NO_TRANSACTIONS</Trans>
                                                                </div>
                                                            </CollectionItem>
                                                    }
                                                </div>
                                            </InfiniteScroll>
                                        </Collection>
									</Tabs.Tab>
								</Tabs>
							</Col>
                            {/* {
                                this.state.currentActiveTab === 'accList' ?
                                    <div className='add-budget-icon budget-modal'>
                                        <Modal
                                            header={t("ADD_ACCOUNT")}
                                            trigger={<Button
                                                floating
                                                large
                                                className="blue"
                                                waves="light"
                                                icon="add"
                                            />}
                                            actions={
                                                <div>
                                                    <Button flat modal="close" waves="light"><Trans>CANCEL</Trans></Button>
                                                </div>
                                            }
                                        >
                                            <AccountForm currencies={this.props.auth.currencies} t={this.props.t}
                                                         onSubmit={this.addUpdateAccount}/>
                                        </Modal>
                                    </div> : null
                            } */}
						</Col>
						<Col s={12} m={12} l={6}>
							<div className={`income-section mobile-no-view ${Object.keys(this.state.currentDayTransactions).length > 0 ? 'detailView':''}`}>
								<div className="col s12">
									<ul className="tabs z-depth-1 detail-tab">
										<li className="tab col">
											<a>
                                                {
                                                    <Trans>DETAILS</Trans>
                                                }
											</a>
										</li>
									</ul>
								</div>
                                {Object.keys(this.state.currentDayTransactions).length > 0 ?
                                    <IncomeDetail
                                        t={t}
                                        userSettings={this.state.userSettings || {}}
                                        onDeleteTransaction={CAT_TYPE_TEXT['EXPENSE'] === this.state.currentDayTransactions.type ? this.deleteTransaction : CAT_TYPE_TEXT['INCOME'] === this.state.currentDayTransactions.type ? this.deleteIncomeTrx : () => null}
                                        currentTransaction={this.state.currentDayTransactions}
                                        onSubmit={this.updateAccountTrx}
                                        auth={this.props.auth}
                                    /> : null
                                }
							</div>
						</Col>

                        <div className='add-icon'>
                            <Modal
                                header={t("EDIT_ACCOUNT")}
                                open={this.state.openEditModal}
                                actions={
                                    <div>
                                        <Button flat modal="close" waves="light" onClick={this.closeAccountEditModel}><Trans>CANCEL</Trans></Button>
                                    </div>
                                }>
                                <AccountForm currencies={this.props.auth.currencies} transaction={this.state.editableAccountData}
                                             t = {this.props.t}
                                             onSubmit={this.addUpdateAccount}
                                />
                            </Modal>
                        </div>

					</Row>
				</div>
				<Loader condition={GET_ACCOUNT_LIST_LOADING}/>
			</div>
        );
    }
}

Account.propTypes = {
    addUpdateAccount: PropTypes.func.isRequired,
    updateAccTrx: PropTypes.func.isRequired,
    getAccountList: PropTypes.func.isRequired,
    getAccountTrxList: PropTypes.func.isRequired,
    deleteAccountTransaction: PropTypes.func.isRequired,
    deleteIncomeTrx: PropTypes.func.isRequired,
    deleteAccountData: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return state;
};

export default withTranslation() (connect(
    mapStateToProps,
    {
        getAccountList,
        getAccountTrxList,
        deleteAccountData,
        deleteAccountTransaction,
        deleteIncomeTrx,
        getCurrencies,
        addUpdateAccount,
        updateAccTrx
    })(withRouter(Account)));

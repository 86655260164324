import axios from 'axios';
import setLoading from './appActions';
import {
    GET_CATEGORIES,
    SEARCH_TRANSACTION,
    TRANSACTION_CACHE
} from './types';

export const cacheTransaction = (object, key, value) => dispatch => {
	dispatch({
		type: TRANSACTION_CACHE,
		payload: Object.assign(object, {[key]: value})
	});
}



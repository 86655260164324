import React, { Component } from 'react';
import {withTranslation, Trans} from 'react-i18next';
import {
    Icon,
	Row,
	Col,
    ProgressBar
} from 'react-materialize';
import { connect } from 'react-redux';
import {
    
    
    
    cacheTransaction
} from "../actions/transactionActions";
import CategorySelector from './common/CategorySelector';
import RecurringSelector from './common/RecurringSelector';
import '../stylesheets/App.css'
import * as Swal from "sweetalert2";
import {
    dayOfYear, getCurrentUTCTime, onChangeInputWheel, createDateFormat, weekOfYear,getYear
} from "./common/Methods";
import moment from 'moment';
import AccountSelector from "./common/AccountSelector";

class IncomeForm extends Component {
    constructor(props) {
        super(props);
        this.imageSelected = false;
        this.state = {
            categoryId: 932,
            disableUpload: true,
            amount: '',
            title: '',
            incomeDate: this.createDate(),
            notes: '',
            imageFile: '',
            currencyCode: '',
            imageFileObj: '',
            _id: '',
            image: '',
            imagePath: '',
            imageFileData: '',
            recurringCategoryId: 0,
            selectedRecurringCategoryId: 0,
            recurringCount: 0,
            repeatTillCount: 0,
            repeatTillDate: '',
			userSettings: JSON.parse(localStorage.getItem('profileDetails')) || {},
        };
        this.incomeMouseWheel = null;
        this.incomeDateRef = null;
        this.lastDate=null;
    }

    componentWillReceiveProps(nextProps) {
        if(!nextProps.INCOME_IMAGE_LOADING && nextProps.incomeAction !== 'ADD_MEDIA') {
            if (nextProps.incomeData && nextProps.incomeData._id && !nextProps.addNewIncome) {
                const incomeData = nextProps.incomeData;
                this.lastDate = nextProps.lastDate;
                this.setState({
                    _id: incomeData._id,
                    categoryId: incomeData.category ? incomeData.category.categoryId : 0,
                    amount: +incomeData.amount,
                    title: incomeData.title,
                    accountId: incomeData.accountId,
                    currencyCode: incomeData.currencyCode,
                    image: incomeData.image || '',
                    imageFileObj: incomeData.imageFile,
                    incomeDate: nextProps.lastDate ? this.createDate(nextProps.lastDate): this.createDate(incomeData.time),
                    notes: incomeData.notes,
                    recurringCategoryId: incomeData.recurringCategoryId || 0,
                    selectedRecurringCategoryId: ( incomeData.repeatTillCount || incomeData.repeatTillDate ) ?  7 : (incomeData.recurringCategoryId || 0),
                    recurringCount: incomeData.recurringCount || 0,
                    repeatTillCount: incomeData.repeatTillCount || 0,
                    repeatTillDate: incomeData.repeatTillDate || ''
                });
            } else {
                this.resetStateData();
            }
        }
        if(nextProps.INCOME_IMAGE_LOADING !== undefined){
            this.imageUploadFlag = nextProps.INCOME_IMAGE_LOADING;
        }
    }

    resetStateData = () => {
        this.setState({
            categoryId: 1,
            amount: '',
            title: '',
            incomeDate: this.createDate(),
            notes: '',
            imageFileObj: '',
            accountId: '',
            _id: '',
            currencyCode: '',
            image: '',
            imageFile: '',
            recurringCategoryId: 0,
            selectedRecurringCategoryId: 0,
            recurringCount: 0,
            repeatTillCount: 0,
            imagePath: '',
            imageFileData: '',
            repeatTillDate: ''
        });
    };

    onCategoryChange = (categoryId) => {
        this.setState({
            categoryId: categoryId
        });
    };

    onRecurringChange = (recurringCategoryId) => {
        this.setState({
            selectedRecurringCategoryId: recurringCategoryId
        });
        this.setState({
            recurringRule:undefined
        });
    };

    getRecurringObj = (recurringObj) => {
        
        this.setState({
            ...recurringObj
        }, e=>console.log('recurring rule', recurringObj, this.state));
    };

    onTextChange = (key, e) => {
        this.setState({
            [key]: e.target.value
        });
    };

    onDateChange = (key, e) => {
        if(e.target.value)
        this.setState({
            [key]: e.target.value
        });
    };

    createDate(date = new Date()) {
        // const dateObj = new Date(date);
        return moment(date).format('YYYY-MM-DD');
    }

    onFileChange = (key, e) => {
        if(e && e.target.files[0]){
            this.readURL(e.target.files[0]);
            let fileName = e.target.files[0].name;
            const extension = fileName.replace(/^.*\./, '');
            this.setState({
                [key]: 'TXIMG' + getCurrentUTCTime() + '.' + extension,
                imageFileObj: e.target.files[0]
            });
            this.imageSelected = true;
            // inheritely calling the addMedia() 
            this.addMedia(e.target.files[0], 'TXIMG' + getCurrentUTCTime() + '.' + extension);
        }
        else{
            return;
        }
    };

    readURL = (input) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            this.setState({imagePath: e.target.result, disableUpload: false})
        };
        reader.readAsDataURL(input);
    };

    addMedia(imageFileObj, image) {
        this.props.addMedia(imageFileObj, image);
        this.imageSelected = false;
        this.setState({
            disableUpload: true
        });
    }

    getAlertBox = (message, type) => {
      const t = this.props.t;
        Swal.fire({
            title: t(message),
            position: 'bottom-start',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
            background: type==='success' ? '#8dc44e' : '#f1521c',
            customClass: {
                title: 'sweet-alert',
                container: 'sweet-alert-container'
            }
        });
    };

    submitForm() {
        if(this.imageSelected) {
            this.getAlertBox('PLEASE_FIRST_UPLOAD_THE_IMAGE', 'error');
        }else {
            const dateObj = moment(this.state.incomeDate).valueOf();
            const data = {
                currencyCode: this.state.currencyCode,
                amount: +this.state.amount,
                categoryId: this.state.categoryId,
                time: moment(this.state.incomeDate).utc().valueOf(),
                accountId: this.state.accountId,
                dayOfYear: dayOfYear(dateObj),
                week:weekOfYear(dateObj),
                year: getYear(dateObj),
                notes: this.state.notes,
                image: this.state.image,
                lastModifyTime: getCurrentUTCTime(),
                createTime: moment().utc().valueOf(),
                month: moment(dateObj).get('month'),
                _id: this.state._id,

            };
            if(this.state.recurringRule) {
                
                data.recurringRule = this.state.recurringRule
            } else {
                data.recurringRule = undefined
            }
            if(this.props.incomeData && this.props.incomeData.time && this.props.incomeData.time !== dateObj) {
                data['dateUpdated'] = true;
            }
            if(this.state.recurringCategoryId) {
                data['recurringCategoryId'] = this.state.recurringCategoryId;
                data['recurringCount'] = this.state.recurringCount;
                data['repeatTillCount'] = this.state.repeatTillCount;
                data['repeatTillDate'] = this.state.repeatTillDate;
            }
            console.log('sddd', this.state, data)
            this.props.onSubmit(data);
            if(!this.state._id) {
                this.resetStateData();
            }
        }
    }

    getSelectedAccountId = (val) => {
        const  currencyCode =  this.props.auth.userAccountList.find(({accountId}) => accountId ===  val).currencyCode
        this.setState({
            accountId: val,
            currencyCode: currencyCode
        })
    };

   

    changeWheel = (e) => {
        if(e==='amount') {
            onChangeInputWheel(this.incomeMouseWheel);
        }
        else {
            onChangeInputWheel(this.incomeDateRef);
        }

    };

    render() {
        const {
			userSettings: { currencyDetails={currencySymbol:""} }
        } = this.state;
        const {
            t
        } = this.props;

        const cusy = this.props.auth.userAccountList.find(({accountId}) => accountId ===  this.state.accountId);;
        let cynmble = this.props.auth.currencies && cusy && cusy !=='' ? this.props.auth.currencies.find(e=>e.currencyCode === cusy.currencyCode) : undefined;
        cynmble = cynmble ? cynmble.currencySymbol :cynmble;
        return <form className="add-edit-expense">
            <div className="content-area">
                <Row>
                    <Col s={12} m={12} l={12}>
                        <CategorySelector
                            t={t}
                            onChange={this.onCategoryChange}
                            value={this.state.categoryId}
                            disabled={this.props.disableCategory}
                            type={2}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col s={12} m={12} l={12}>
                        <AccountSelector
                            value={this.state.accountId}
                            onChange={this.getSelectedAccountId}
                            type={2}
                        />
                    </Col>
                </Row>

                <Row>
                    <div className="input-field col m6 s12">
                        <i className={"fas prefix " + (currencyDetails.currencySymbol.length > 1 ? 'long-symbole' : 'currecny')}>
                            {cynmble !== '' && cynmble !== undefined ? cynmble:   currencyDetails.currencySymbol}
                        </i>
                        <input
                            id="icon_prefix1"
                            type="number"
                            ref={c => this.incomeMouseWheel = c}
                            onWheel={() => this.changeWheel('amount')}
                            min={0}
                            name="amount"
                            value={this.state.amount}
                            required
                            onChange={this.onTextChange.bind(this, 'amount')}
                            />
                        <label htmlFor="icon_prefix1" className={this.state.amount ? 'active' : ''}><Trans>AMOUNT</Trans></label>
                    </div>
                </Row>

                <Row>
                    <div className="input-field col m6 s12 input-expense-date margin-bottom-40">
                        <i className="fas prefix">
                            <Icon>date_range</Icon>
                        </i>
                        <span id="date-text" style={{width: 'calc(100% - 4rem)', float: 'left', marginLeft: '4rem', borderBottom: '1px solid #9e9e9e', height: '2rem'}}>{createDateFormat(t, this.state.incomeDate)}</span>
                        <input  type="date"
                                className="date-input"
                                name="date"
                                ref={c => this.incomeDateRef = c}
                                value={this.state.incomeDate}
                                onWheel={() => this.changeWheel('date')}
                                required
                                onChange={this.onDateChange.bind(this, 'incomeDate')}/>
                    </div>
                </Row>

                <Row>
                    <RecurringSelector
                        _id={this.state._id}
                        onChange={this.onRecurringChange}
                        recurringRule={this.props.incomeData.recurringRule}
                        value={this.state.selectedRecurringCategoryId}
                        recurringCount={this.state.recurringCount}
                        recurringId={this.state.recurringCategoryId}
                        repeatTillDate={this.state.repeatTillDate}
                        repeatTillCount={this.state.repeatTillCount}
                        dataLoading={this.props.dataLoading}
                        time={this.state.incomeDate ? moment(this.state.incomeDate).valueOf() : getCurrentUTCTime}
                        getRecurringObj={this.getRecurringObj}
                        disabled={this.props.disableRecurrence || false}
                    />
                </Row>

                <Row>
                    <div className="input-field col m6 s12 align-horizontal">
                        <i className="fas prefix">
                            <Icon>comments</Icon>
                        </i>
                        <input
                            id="icon_prefix2"
                            type="text"
                            value={this.state.notes}
                            onChange={this.onTextChange.bind(this, 'notes')}
                            name="notes" />
                        <label htmlFor="icon_prefix2" className={this.state.notes ? 'active' : ''}><Trans>NOTES</Trans></label>
                    </div>
                </Row>

                <Row>
                    <div className="input-field col m6 s6 attach-Photo">
                        <i className="material-icons prefix">file</i>
                        <input
                            id="icon_prefix3"
                            type="file"
                            value={this.state.imageFile}
                            onChange={this.onFileChange.bind(this, 'image')}
                            name="file" />
                        <label htmlFor="icon_prefix3" className={this.state.imageFile ? 'active' : ''}> <i className="material-icons dp48">branding_watermark</i> <Trans>ATTACH_PHOTO</Trans></label>
                        { this.imageUploadFlag ? <div className={"progress-bar-control"}><ProgressBar /></div> : null}
                    </div>
                    <div className="input-field col m6 s6 images-up">
                        {
                            this.state.imagePath ? <img src={this.state.imagePath} alt={t('NO_IMAGE')} /> :
                            !this.props.addNewIncome && this.props.incomeData && this.props.incomeData.imageFile ?
                                <img src={this.props.incomeData.imageFile} alt={t('NO_IMAGE')} /> : ''
                        }
                    </div>
                </Row>
            </div>

            <Row>
                <div className="modal-footer-sec">
                    <div className="input-field col m6 s12">
                        <button className={'btn waves-effect waves-light light-blue accent-4 ' + (!this.imageSelected ?  'modal-close' : '')}
                                type="button" name="action" onClick={this.submitForm.bind(this)}>
                            <Trans>DONE</Trans>
                            <i className="material-icons right">done</i>
                        </button>
                    </div>
                </div>
            </Row>
        </form>
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    categories: state.categories,
    transactionData: state.transactionData,
});

export default withTranslation()(connect(mapStateToProps, {
    
    
    
    cacheTransaction
})(IncomeForm));

import {
    PAID_BILLING_DATA_LOADING,
    UPCOMING_BILLING_DATA_LOADING,
    OVERDUE_BILLING_DATA_LOADING,
    RECURRING_BILLING_DATA_LOADING,
    GET_PAID_BILL_DATA_SUCCESS,
    GET_OVERDUE_BILL_DATA_SUCCESS,
    GET_UPCOMING_BILL_DATA_SUCCESS,
    GET_RECURRING_BILL_DATA_SUCCESS, BILL_DATA_LOADING, BILL_DATA_FAILED, BILL_DATA_ACTION, GET_RECURRING_SUCCESS,
    UPDATE_BILL_DATA_LIST,
    BILL_PAYMENT_FETCH_SUCCESS
} from './types';
import { handleErrors } from './authActions';
import * as api from '../services/ApiCall';
import {getBillingStats} from "./dashboardActions";
import {getCurrentUTCTime, getMidDayTime} from "../components/common/Methods";
import {ENV} from "../actions/config";
const getLoadingDispatch = (type, state) => ({type, state});

//Get paid bill data 
export const getPaidBills = (year) => dispatch => {
    dispatch(getLoadingDispatch(PAID_BILLING_DATA_LOADING, true));
    dispatch(getLoadingDispatch(BILL_DATA_ACTION, ''));
    api.callPost('api/userdata/getPaidBillingTransactions', year, ENV.WEB_API)
        .then(data => {
            dispatch({
                type: GET_PAID_BILL_DATA_SUCCESS,
                payload: data.result
            });
            dispatch(getLoadingDispatch(PAID_BILLING_DATA_LOADING, false));
        })
        .catch(err => dispatch(handleErrors(err)));
};

//Get Recurring Bills data 
export const getRecurringBills = (year) => dispatch => {
    dispatch(getLoadingDispatch(RECURRING_BILLING_DATA_LOADING, true));
    dispatch(getLoadingDispatch(BILL_DATA_ACTION, ''));
    api.callPost('api/userdata/getRecurringBills', year, ENV.WEB_API)
        .then(data => {
            dispatch({
                type: GET_RECURRING_BILL_DATA_SUCCESS,
                payload: data.result
            });
            dispatch(getLoadingDispatch(RECURRING_BILLING_DATA_LOADING, false));
            dispatch(getLoadingDispatch(BILL_DATA_ACTION, ''));
        })
        .catch(err => dispatch(handleErrors(err)));
};

//Get overdue bill data 
export const getOverdueBills = (year) => dispatch => {
    dispatch(getLoadingDispatch(OVERDUE_BILLING_DATA_LOADING, true));
    dispatch(getLoadingDispatch(BILL_DATA_ACTION, ''));
    api.callPost('api/userdata/getOverDueBillingTransactions', year, ENV.WEB_API)
        .then(data => {
            dispatch({
                type: GET_OVERDUE_BILL_DATA_SUCCESS,
                payload: data.result
            });
            dispatch(getLoadingDispatch(OVERDUE_BILLING_DATA_LOADING, false));
        })
        .catch(err => dispatch(handleErrors(err)));
};

//Get upcoming bill data 
export const getUpcomingBills = (year) => dispatch => {
    dispatch(getLoadingDispatch(UPCOMING_BILLING_DATA_LOADING, true));
    dispatch(getLoadingDispatch(BILL_DATA_ACTION, ''));
    api.callPost('api/userdata/getUpcomingBillingTransactions', year, ENV.WEB_API)
        .then(data => {
            dispatch({
                type: GET_UPCOMING_BILL_DATA_SUCCESS,
                payload: data.result
            });
            dispatch(getLoadingDispatch(UPCOMING_BILLING_DATA_LOADING, false));
        })
        .catch(err => dispatch(handleErrors(err)));
};

//Delete Transaction data
export const deleteTransaction = (data, year, month, activeTab, deleteSingleRecord = true) => dispatch => {
    dispatch(getLoadingDispatch(BILL_DATA_LOADING, true));
    let dataObj = data;
    let apiURL = 'api/userdata/deleteRecurringTrx';
    if(deleteSingleRecord && activeTab !== 'recurring') {
        apiURL = 'api/userdata/deleteTransactionWeb';
        dataObj = {
            lastModifyTime: getCurrentUTCTime(),
            transactions: [
                {
                    _id: data._id
                }
            ]
        }
    }
    dataObj.lastModifyTime = getCurrentUTCTime();
    api.callPost(apiURL, dataObj, ENV.WEB_API)
        .then(res => {
            dispatch(updateBillsDataList({year: year}, activeTab));
            dispatch(getBillingStats({
                month: month,
                year: year
            }));
            dispatch(getLoadingDispatch(BILL_DATA_ACTION, 'DELETE_BILL'));
        })
        .catch(err => dispatch(handleErrors(err, BILL_DATA_FAILED)));
};

export const getRecurringDataById = (recurringServerId, editableRecord) => dispatch => {
    dispatch(getLoadingDispatch(BILL_DATA_LOADING, true));
    api.callPost('api/userdata/getRecurringParentTrx', recurringServerId, ENV.WEB_API)
        .then(({Transaction}) => {
            const recurringData = editableRecord ? {
                recurringCategoryId: Transaction.recurringCategoryId,
                recurringCount: Transaction.recurringCount,
                repeatTillCount: Transaction.repeatTillCount,
                repeatTillDate: Transaction.repeatTillDate
            } : Transaction;
            dispatch({
                type: GET_RECURRING_SUCCESS,
                payload: recurringData
            });
            dispatch(getLoadingDispatch(BILL_DATA_ACTION, 'GET_RECURRING'));
            dispatch(getLoadingDispatch(BILL_DATA_LOADING, false));
        }).catch(err => dispatch(handleErrors((err && err.data ? err.data : err), BILL_DATA_FAILED)));
};

export const addUpdateBill = (data, year, month, activeTab, cb) => dispatch => {
    data.lastModifyTime = getCurrentUTCTime();
    if(data.time){
        data.time = getMidDayTime(data.time);
    }
    dispatch(getLoadingDispatch(BILL_DATA_LOADING, true));
    api.callPost('api/userdata/addUpdateBill', data, ENV.WEB_API)
        .then(res => {
            dispatch(updateBillsDataList({year: year}, activeTab));
            if(!data._id) {
                dispatch(getLoadingDispatch(BILL_DATA_ACTION, 'ADD_BILL'));
            }else {
                dispatch(getLoadingDispatch(BILL_DATA_ACTION, 'UPDATE_BILL'));
            }
            dispatch(getBillingStats({
                month: month,
                year: year
            }));
        })
        .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), BILL_DATA_FAILED)));
};

export const markBillPaid = (data, year, month, activeTab) => dispatch => {
    dispatch(getLoadingDispatch(BILL_DATA_LOADING, true));
    data.lastModifyTime = getCurrentUTCTime();
    api.callPost('api/userdata/markBillPaid', data, ENV.WEB_API)
        .then(res => {
            dispatch(updateBillsDataList({year: year}, activeTab));
            dispatch(getBillingStats({
                month: month,
                year: year
            }));
            dispatch(getLoadingDispatch(BILL_DATA_ACTION, 'BILL_MARKED'));
        })
        .catch(err => dispatch(handleErrors((err && err.data ? err.data : err), BILL_DATA_FAILED)));
};

export const updateBillsDataList = (year, activeTab) => dispatch => {
    let apiInfo = {
        url: 'getUpcomingBillingTransactions',
        key: 'upcomingTransactions'
    };
    if(activeTab === 'recurring') {
        apiInfo = {
            url: 'getRecurringBills',
            key: 'recurringTransactions'
        }
    }else if(activeTab === 'paid') {
        apiInfo = {
            url: 'getPaidBillingTransactions',
            key: 'paidTransactions'
        }
    }else if(activeTab === 'overdue') {
        apiInfo = {
            url: 'getOverDueBillingTransactions',
            key: 'overdueTransactions'
        }
    }
    api.callPost('api/userdata/' + apiInfo.url, year)
        .then(data => {
            dispatch({
                type: UPDATE_BILL_DATA_LIST,
                key: apiInfo.key,
                payload: data.result
            });
            dispatch(getLoadingDispatch(BILL_DATA_LOADING, false));
        })
        .catch(err => dispatch(handleErrors(err)));
};

export const updateBillsList = (year, activeTab) => dispatch => {
    dispatch(getLoadingDispatch(BILL_DATA_LOADING, true));
    let apiInfo = {
        url: 'getUpcomingBillingTransactions',
        key: 'upcomingTransactions'
    };
    if(activeTab === 'recurring') {
        apiInfo = {
            url: 'getRecurringBills',
            key: 'recurringTransactions'
        }
    }else if(activeTab === 'paid') {
        apiInfo = {
            url: 'getPaidBillingTransactions',
            key: 'paidTransactions'
        }
    }else if(activeTab === 'overdue') {
        apiInfo = {
            url: 'getOverDueBillingTransactions',
            key: 'overdueTransactions'
        }
    }
    api.callPost('api/userdata/' + apiInfo.url, year, ENV.WEB_API)
        .then(data => {
            dispatch({
                type: UPDATE_BILL_DATA_LIST,
                key: apiInfo.key,
                payload: data.result
            });
            dispatch(getLoadingDispatch(BILL_DATA_ACTION, 'LIST_UPDATE'));
            dispatch(getLoadingDispatch(BILL_DATA_LOADING, false));
        })
        .catch(err => dispatch(handleErrors(err)));
};

export const fetchBillPaymentDetails = (id) =>dispatch=> {
    api.callPost('api/userdata/getBillExpenses', {localIdLong: id}, ENV.WEB_API).then(resp=>{
        dispatch({
            type: BILL_PAYMENT_FETCH_SUCCESS,
            payload: resp.result
        })
        dispatch(getLoadingDispatch(BILL_DATA_ACTION, 'BILL_PAYMENT_FETCH_SUCCESS'));
    }).catch(err=>{console.log({err});dispatch(handleErrors(err))});
}
import {
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_LOADING,
    CHANGE_PASSWORD_FAILED,
    USER_UPDATE_SUCCESS,
    GET_USER_AVATAR_IMAGE,
    SAVE_USER_PROFILE_SUCCESS,
    SETTING_DATA_ACTION
} from './types';
import { ENV } from './config';
import setLoading from './appActions';
import { handleErrors } from './authActions';
import * as api from '../services/ApiCall';
import {getCurrentUTCTime} from "../components/common/Methods";

const getLoadingDispatch = (type, state) => ({type, state});

//change password
export const changePassword = (passwordData) => dispatch => {
    dispatch(getLoadingDispatch(CHANGE_PASSWORD_LOADING, true));
    api.callPost('api/users/changePassword', passwordData)
        .then(data => {
            dispatch({
                type: CHANGE_PASSWORD_SUCCESS,
                payload: data
            });
        })
        .catch(err => {
            const errdata = handleErrors((err && err.data ? err.data : err), CHANGE_PASSWORD_FAILED);
            dispatch(errdata);
        });
};

//user update
export const userUpdate = (formData, callBack) => dispatch =>{
    //dispatch(getLoadingDispatch(USER_UPDATE_LOADING, true));
    return api.callPut('api/users/update', formData)
        .then(data => {
            dispatch({
                type: USER_UPDATE_SUCCESS,
                payload: formData
            });
            if(typeof callBack === 'function') {
                callBack();
            }
        }).catch(err => {
            console.error('error===', err);
        });
};

export const saveSettings = ( settings, callback ) => dispatch => {
    settings.lastModifyTime = getCurrentUTCTime();
    dispatch(setLoading(true));
    api.callPost('api/users/settings', settings,ENV.API)
        .then(data => {
            dispatch({
                type: SAVE_USER_PROFILE_SUCCESS,
                payload: data.code
            });
            callback();
            dispatch(getLoadingDispatch(SETTING_DATA_ACTION, 'SETTING_UPDATED'));
        })
        .catch(err => dispatch(handleErrors(err)));
};

export const uploadMedia = (dataFile, fileName) => {
    const formData = new FormData();
    formData.append('file', dataFile, fileName);
    return api.uMediaPost(formData);
};

//User Avatar
export const getMedia = (fileName, cb) => dispatch => {
    // dispatch(getLoadingDispatch(USER_AVATAR_LOADING, true));
    api.getImage('api/userdata/getUserMedia/' + fileName)
        .then((response) => {
            let image = btoa(
                new Uint8Array(response.data)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            // dispatch(getLoadingDispatch(USER_AVATAR_ACTION, 'GET_MEDIA'));
            const imageData = `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
            dispatch({
                type: GET_USER_AVATAR_IMAGE,
                payload: imageData
            });
            if(typeof cb === 'function') {
                cb(imageData)
            }
            // dispatch(getLoadingDispatch(USER_AVATAR_LOADING, false));
        }).catch(err=>{
            console.error({err}, 'test');
    })
};
export const getProfileImage = (imgId, cb, userId = false) => {
    if(!userId) {
        const userDetail = JSON.parse(localStorage.getItem('profileDetails'));
        userId = userDetail.userId;
    }
    api.umediaGet(`media/${userId}/${imgId}`).then(res=>{
        cb(res);
    }).catch(err=>{
        cb(err, false);
    })
};
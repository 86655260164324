import React, { Component, useState, useRef, useEffect } from "react";
import Navbar from "./Navbar";
import { withRouter, Redirect, Link } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";
import { Helmet } from "react-helmet";
import { Input, Icon } from "react-materialize";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  loginUserOtp,
  resendOTP,
  preSignin,
  getCurrencies,
  resendActivation,
  clearErrors,
} from "../actions/authActions";
import Spinner from "./common/Spinner";
import { ENV } from "../actions/config";
import "../stylesheets/App.css";
import CookieDisclaimer from "./common/CookieDisclaimer";
import { browserName } from "./common/Methods";
import { iconUrl } from "../actions/config";
import { TZ_WITH_CURRENCY } from "../utils/tzwithcountry";
import Swal from "sweetalert2";

const showMessage = (msg, type, time) => {
  Swal.fire({
    title: msg,
    position: 'bottom-start',
    toast: true,
    showConfirmButton: false,
    timer: time ? time : 2000,
    background: type === 'success' ? '#8dc44e' : '#f1521c',
    customClass: {
      title: 'sweet-alert',
      container: 'sweet-alert-container'
    }
  });
}

const SingInMail = (props) => {
  const [formState, setFormState] = useState({ showPass: false, preSign: true });
  const { currencies = [] } = props.auth;
  const inputEl = useRef(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('status');
    if (status && status === '340') {
      showMessage(props.t('ACCOUNT_NOT_EXISTS'), null, 10000);
      const newUrl = window.location.origin + window.location.pathname;
      window.history.pushState({}, document.title, newUrl);
    }
    if (localStorage.getItem('jwtToken')) {
      props.history.replace('/loadingprofile');
    }

    if (props.location.state && props.location.state.userData?.inviteeEmail) {
      setFormState({
        ...formState,
        email: props.location.state.userData.inviteeEmail,
        ownerFirstName: props.location.state.userData.ownerFirstName,
        ownerEmail: props.location.state.userData.ownerEmail,
        groupToken: props.location.state.token,
        showRefralMessage: true
      });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let currency = '';
    try {
      currency = TZ_WITH_CURRENCY[Intl.DateTimeFormat().resolvedOptions().timeZone];
    } catch (e) { }

    if (formState.preSign) {
      props.preSignin({
        email: formState.email,
        deviceId: 'WEB',
        currency: currency,
        grpToken: formState.groupToken
      }, (res) => {
        if (res.code !== 340) {
          setFormState({ ...formState, preSign: false, count: 60, isNewUserSignup: false });
        } else {
          showMessage(props.t('ACCOUNT_NOT_EXISTS'));
        }
      });
    } else {
      props.loginUserOtp({
        email: formState.email,
        otp: formState.otp,
        deviceType: "WEB",
        deviceId: "WEB",
        deviceName: browserName()
      }, 
      () => props.history.replace('/loadingprofile'),
      () => showMessage(props.t('INVALID_OTP')));
    }
  };

  const googleLogin = () => {
    window.location.href = `${ENV.API}auth/google/auth`;
  };

  return (
    <>
      <Helmet>
        <title>{props.t("SIGN_IN")}</title>
      </Helmet>
      {/* <Navbar disableMenu={true} disableSideLink={true} /> */}
      <div className="login-container">
        <div className="login-left-container">
          <div className="login-logo">
            <img src="https://www.timelybills.app/images/images/logo-dark.webp" alt="Logo" />
          </div>
          <div className="left-content">
            <h1><Trans>SIGN_IN</Trans></h1>
            <p><Trans>TO_TIMELYBILLS_ACCOUNT</Trans></p>
            
            <form onSubmit={handleSubmit}>
              <div className="material-input">
                <input
                  type="email"
                  id="email-input"
                  value={formState.email}
                  onChange={(e) => setFormState({ ...formState, email: e.target.value })}
                  placeholder={props.t("EMAIL")}
                  disabled={formState.groupToken !== undefined}
                  style={formState.groupToken !== undefined ? { background: '#d1d1d1' } : {}}
                />
              </div>

              {!formState.preSign && (
                <>
                  <div className="material-input">
                    <input
                      type="text"
                      id="otp-input"
                      value={formState.otp}
                      onChange={(e) => setFormState({ ...formState, otp: e.target.value })}
                      placeholder={props.t("OTP")}
                    />
                  </div>
                  <div className="otp-resend" style={{textAlign: 'center'}}>
                    <Trans>OPT_SENT_ALREADY</Trans>
                    <span 
                      className="resend-btn"
                      style={{ color: 'black'}}
                      onClick={() => props.resendOTP(formState.email, 
                        () => showMessage(props.t('OPT_SENT_ALREADY'), 'success'),
                        () => showMessage(props.t('OPT_SENT_ERROR')))}
                    >
                      <Trans>RESEND</Trans>
                    </span>
                  </div>
                </>
              )}

           
              <button
                className="sign-in-btn"
                id="sign-in-btn"
                disabled={props.loading.loading || (formState.preSign && !formState.email)}
                aria-disabled={props.loading.loading || (formState.preSign && !formState.email)}
              >
                {props.loading.loading ? (
                  <Spinner />
                ) : formState.preSign ? (
                  <Trans>NEXT</Trans>
                ) : (
                  <Trans>SIGN_IN</Trans>
                )}
              </button>
            </form>

            <div className="or-divider" aria-hidden="true">
              <Trans>OR</Trans>
            </div>
            
            <div className="center-align">
                <button className="google-btn" onClick={googleLogin}>
                  Continue with 
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/640px-Google_2015_logo.svg.png"
                    alt="Google Logo"
                    className="google-logo"
                    width={60}
                    height={20}
                  />
                </button>
              </div>

            <div className="need-an-account">
              <Trans>NEED_ACCOUNT</Trans>{' '}
              <a href="https://www.timelybills.app/download"><Trans>MOBILE_APP</Trans></a>
            </div>
          </div>
        </div>

        <div className="login-right-container">
          <h2>
            <Trans>ALL_IN_ONE_MONEY_MANAGER</Trans>
          </h2>
          <p>
            <Trans>TRACK_EXPENSES_AND_MORE</Trans>
          </p>
          <img
            src="https://static.timelybills.app/img/cashflow-budget.png"
            alt="Login Illustration"
          />
        </div>
      </div>
      <CookieDisclaimer />
    </>
  );
};

const mapStateToProps = (state) => ({
  currencies: state.currencies,
  auth: state.auth,
  errors: state.errors,
  loading: state.loading,
});

export default withTranslation()(
  connect(mapStateToProps, {
    loginUserOtp,
    resendOTP,
    preSignin,
    resendActivation,
    getCurrencies,
    clearErrors,
  })(withRouter(SingInMail))
);
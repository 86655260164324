import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { IntlProvider } from "react-intl";
import routes from './RoutesDetail';
import { PrivateRoute } from './PrivateRoute';
import { addAppLocaleData } from '../../localeData';
addAppLocaleData();

export class Routes extends Component{
    render() {
        const location = window.location.pathname;
        if(location !== '/signup' && location !== '/signin' && location !== '/') {
            document.getElementById('siteseal').style.display = "none";
        }else {
            document.getElementById('siteseal').style.display = "block";
        }
        return (
			<IntlProvider locale={window.navigator.language}>
				<div>
					{
						routes
							.map(({ path, component, isPrivate = false, isProFeature = false}) => ({
								Route: ( isPrivate ? PrivateRoute : Route ),
								_props: { path, component, key: path, exact: true, isProFeature: false},
							}))
							.map(({Route, _props}) =>
								<Route
									{..._props}
								/>
							)
					}
				</div>
			</IntlProvider>
        );
    }
}

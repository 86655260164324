import React, { Component } from 'react';
import {withTranslation, Trans} from 'react-i18next';
import {
    Icon,
	Row,
	Col,
    ProgressBar
} from 'react-materialize';
import { connect } from 'react-redux';
import {
    
    
    
    cacheTransaction
} from "../actions/transactionActions";
import CategorySelector from './common/CategorySelector';
import {
    onChangeInputWheel, createDateFormat, getCurrentUTCTime
} from "./common/Methods";
import '../stylesheets/App.css'
import * as Swal from "sweetalert2";
import moment from 'moment';
import AccountSelector from "./common/AccountSelector";
import MerchantSelector from "./common/MerchantSelector";

class ExpenseForm extends Component {
    constructor(props) {
        super(props);
        this.imageSelected = false;
        this.state = {
            categoryId: 31,
            merchantId: null,
            merchantName: null,
            merchantIcon: null,
            amount: '',
            disableUpload: true,
            title: '',  
            expenseDate: this.createDate(),
            notes: '',
            imageFile: '',
            imageFileObj: '',
            _id: '',
            accountId: '',
            image: '',
            imagePath: '',
            imageFileData: '',
			userSettings: JSON.parse(localStorage.getItem('profileDetails')) || {},
        };
        this.expenseMouseWheel = null;
        this.expenseDateRef = null;
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.expenses){
            this.imageUploadFlag = nextProps.expenses.EXPENSE_IMAGE_LOADING;
        }
    }
    componentWillMount() {
        if(this.props.expenseData) {
            const expenseData = this.props.expenseData;
            this.setState({
                _id: expenseData._id,
                categoryId: expenseData.category.categoryId,
                merchantId: expenseData.merchantId,
                merchantName: expenseData.merchantName,
                merchantIcon: expenseData.merchantIcon,
                amount: expenseData.amount,
                accountId: expenseData.accountId,
                currencyCode: expenseData.currencyCode,
                title: expenseData.title,
                image: expenseData.image || '',
                imageFileObj: expenseData.imageFile,
                expenseDate: this.createDate(expenseData.time),
                notes: expenseData.notes
            });
            if(expenseData.image && (typeof this.props.getMedia !== 'undefined')) {
                this.props.getMedia(expenseData.image, (imageData) => {
                    this.setState({
                        imageFileObj: imageData
                    });
                });
            }
        }
    }

    onCategoryChange = (categoryId,name) => {
        this.setState({
            categoryId: categoryId,
            // title: selectedCategory ? selectedCategory.name : '' ,
        });
    };

    onMerchantChange = (merchant) => {
        this.setState({
            merchantId: merchant.merchantId,
            merchantName: merchant.merchantName,
            merchantIcon: merchant.merchantIcon
            // title: selectedCategory ? selectedCategory.name : '' ,
        });
    };

    onTextChange = (key, e) => {
        this.setState({
            [key]: e.target.value
        });
    };

    onDateChange = (key, e) => {
        if(e.target.value)
        this.setState({
            [key]: e.target.value
        });
    };

    createDate(date = new Date()) {
        // const dateObj = new Date(date);
        return moment(date).format('YYYY-MM-DD');
    }


    getAlertBox = (message, type) => {
      const t = this.props.t;
        Swal.fire({
            title: t(message),
            position: 'bottom-start',
            toast: true,
            showConfirmButton: false,
            timer: 2000,
            background: type==='success' ? '#8dc44e' : '#f1521c',
            customClass: {
                title: 'sweet-alert',
                container: 'sweet-alert-container'
            }
        });
    };

    submitForm() {
        if(this.imageSelected) {
            this.getAlertBox('PLEASE_FIRST_UPLOAD_THE_IMAGE', 'error');
        }else {
            let form = {...this.state};
            form.expenseDate = moment(this.state.expenseDate).utc().valueOf();
            this.props.onSubmit(form);
            if(!this.state._id) {
                this.setState({
                    categoryId: 31,
                    merchantId: null,
                    merchantName: null,
                    merchantIcon: null,
                    accountId: '',
                    amount: '',
                    title: '',
                    expenseDate: this.createDate(),
                    notes: '',
                    imageFile: '',
                    imageFileObj: '',
                    _id: '',
                    image: '',
                    imagePath: '',
                    imageFileData: ''
                });
            }
        }
    }
    onFileChange = (key, e) => {
        if(e && e.target.files[0]){
            this.readURL(e.target.files[0]);
            let fileName = e.target.files[0].name;
            const extension = fileName.replace(/^.*\./, '');
            this.setState({
                [key]: 'TXIMG' + getCurrentUTCTime() + '.' + extension,
                imageFileObj: e.target.files[0]
            });
            this.imageSelected = true;
            // inheritely calling the addMedia() 
            this.addMedia( e.target.files[0],'TXIMG' + getCurrentUTCTime() + '.' + extension)
        }
        else{
            return;
        }
    };
    
    readURL = (input) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            this.setState({imagePath: e.target.result, disableUpload: false});
        };
        reader.readAsDataURL(input);
    };
    addMedia(imageFileObj,image) {
        this.props.addMedia(imageFileObj, image);
        this.imageSelected = false;
        this.setState({
            disableUpload: true
        });
    }

    changeWheel = (e) => {
        if(e==='amount') {
            onChangeInputWheel(this.expenseMouseWheel);
        }
        else {
            onChangeInputWheel(this.expenseDateRef);
        }
    };

    getSelectedAccountId = (val) => {
        const  currencyCode =  this.props.auth.userAccountList.find(({accountId}) => accountId ===  val).currencyCode
        this.setState({
            accountId: val,
            currencyCode: currencyCode
        })
    };

    render() {
        const {
			userSettings: { currencyDetails }
        } = this.state;
        const {
            t
        } = this.props;
        const cusy = this.props.auth.userAccountList.find(({accountId}) => accountId ===  this.state.accountId);;
        let cynmble = this.props.auth.currencies && cusy && cusy !=='' ? this.props.auth.currencies.find(e=>e.currencyCode === cusy.currencyCode) : undefined;
        cynmble = cynmble ? cynmble.currencySymbol :cynmble;
        return <form className="add-edit-expense">
            <Row>
                <Col s={12} m={12} l={12}>
                    <CategorySelector
                        t={t}
                        onChange={this.onCategoryChange}
                        value={{ categoryId: this.state.categoryId }}
                        type={1}
                    />
                </Col>
            </Row>
            <Row>
                <Col s={12} m={12} l={12}>
                    <MerchantSelector
                        t={t}
                        onChange={this.onMerchantChange}
                        value={{ merchantId: this.state.merchantId }}
                    />
                </Col>
            </Row>
            <Row>
                <Col s={12} m={12} l={12}>
                    <AccountSelector
                        value={this.state.accountId}
                        onChange={this.getSelectedAccountId}
                        type={1}
                    />
                </Col>
            </Row>

            <Row>
                <div className="input-field col m6 s12">
                    <i className={"fas prefix " + (currencyDetails.currencySymbol.length > 1 ? 'long-symbole' : '')}>
                    {cynmble !== '' && cynmble !== undefined ? cynmble:   currencyDetails.currencySymbol}
                    </i>
                    <input
                        id="icon_prefix"
                        type="number"
                        ref={c => this.expenseMouseWheel = c}
                        onWheel={() => this.changeWheel('amount')}
                        min={0}
                        name="amount"
                        value={this.state.amount}
                        required
                        onChange={this.onTextChange.bind(this, 'amount')}
                        />
                    <label htmlFor="icon_prefix" className={this.state.amount ? 'active' : ''}>
                        <Trans>AMOUNT</Trans>
                    </label>
                </div>
            </Row>

            <Row>
                <div className="input-field col m6 s12 input-expense-date">
                    <i className="fas prefix">
                        <Icon>date_range</Icon>
                    </i>
                        <span id="date-text" style={{width: 'calc(100% - 4rem)', float: 'left', marginLeft: '4rem', borderBottom: '1px solid #9e9e9e', height: '2rem'}}>{createDateFormat(t, this.state.expenseDate)}</span>
                        <input  type="date"
                                className="date-input"
                                placeholder="Select Date"
                                name="date"
                                value={this.state.expenseDate}
                                ref={c => this.expenseDateRef = c}
                                onWheel={() => this.changeWheel('date')}
                                required
                                onChange={this.onDateChange.bind(this, 'expenseDate')}/>
                </div>
            </Row>

            <Row>
                <div className="input-field col m6 s12 align-horizontal">
                    <i className="fas prefix">
                        <Icon>comments</Icon>
                    </i>
                    <input
                        id="icon_prefix"
                        type="text"
                        value={this.state.notes}
                        onChange={this.onTextChange.bind(this, 'notes')}
                        name="notes" />
                    <label htmlFor="icon_prefix" className={this.state.notes ? 'active' : ''}>
                        <Trans>NOTES</Trans>...
                    </label>
                </div>
            </Row>

            <Row>
                <div className="input-field col m6 s6 attach-Photo">
                    <i className="material-icons prefix">file</i>
                    <input
                        id="icon_prefix"
                        type="file"
                        value={this.state.imageFile}
                        onChange={this.onFileChange.bind(this, 'image')}
                        name="file" />
                    <label htmlFor="icon_prefix" className={this.state.imageFile ? 'active' : ''}> <i className="material-icons dp48">branding_watermark</i>
                        <Trans>ATTACH_PHOTO</Trans>
                    </label>
                    { this.imageUploadFlag ? <div className={"progress-bar-control"}><ProgressBar /></div> : null}
                </div>
                <div className="input-field col m6 s6 images-up">
                    {
                        this.state.imagePath ? <img src={this.state.imagePath} alt={t('NO_IMAGE')} /> :
                        this.props.expenseData && this.props.expenseData.imageFile ? <img src={this.props.expenseData.imageFile} alt={t('NO_IMAGE')} /> : ''
                    }
                </div>
            </Row>

            <Row>
                <div className="modal-footer-sec">
                    <div className="input-field col m6 s12">
                        <button className={'btn waves-effect waves-light light-blue accent-4 ' + (!this.imageSelected ?  'modal-close' : '')}
                                type="button" name="action" onClick={this.submitForm.bind(this)}><Trans>DONE</Trans>
                            <i className="material-icons right">done</i>
                        </button>
                    </div>
                </div>
            </Row>
        </form>
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    categories: state.categories,
    transactionData: state.transactionData

});

export default withTranslation()(connect(mapStateToProps, {
    
    
    
    cacheTransaction
})(ExpenseForm));

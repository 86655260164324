import {
    GET_BILL_STATS_SUCCESS,
    GET_EXPENSE_STATS_SUCCESS,
    GET_BY_CATEGORY_STATS_SUCCESS,
    GET_BY_TRENDS_STATS_SUCCESS,
    GET_BILL_STATS_LOADING,
    GET_EXPENSE_STATS_LOADING,
    GET_BY_CATEGORY_STATS_LOADING,
    GET_BY_TRENDS_STATS_LOADING, SEND_USER_STATEMENT, SEND_USER_STATEMENT_SUCCESS, GET_BY_TRENDS_CATEGORY_SUCCESS,
    GET_BY_TRENDS_CATEGORY_LOADING, STATEMENT_ACTION
} from './types';
import { handleErrors } from './authActions';
import * as api from '../services/ApiCall';
import moment from 'moment'
import {ENV} from "../actions/config";

const getLoadingDispatch = (type, state) => ({type, state});

// Expense Stats
export const getExpensesStats = (month) => dispatch => {
    dispatch(getLoadingDispatch(GET_EXPENSE_STATS_LOADING, true));
    api.callPost('api/userdata/getExpenseStats', month, ENV.WEB_API)
        .then(data => {
            dispatch({
                type: GET_EXPENSE_STATS_SUCCESS,
                payload: data
            });
            dispatch(getLoadingDispatch(GET_EXPENSE_STATS_LOADING, false));
        })
        .catch(err => dispatch(handleErrors(err)));
};

// Bill Stats
export const getBillingStats = (month) => dispatch => {
    dispatch(getLoadingDispatch(GET_BILL_STATS_LOADING, true));
    api.callPost('api/userdata/getBillingStats', month, ENV.WEB_API)
        .then(data => {
            dispatch({
                type: GET_BILL_STATS_SUCCESS,
                payload: data
            });
            dispatch(getLoadingDispatch(GET_BILL_STATS_LOADING, false));
        })
        .catch(err => dispatch(handleErrors(err)));
};

// Category Stats
export const getExpenseByCategory = (month) => dispatch => {
    dispatch(getLoadingDispatch(GET_BY_CATEGORY_STATS_LOADING, true));
    api.callPost('api/userdata/getExpenseByCategory', month, ENV.WEB_API)
        .then(data => {
            dispatch({
                type: GET_BY_CATEGORY_STATS_SUCCESS,
                payload: data
            });
            dispatch(getLoadingDispatch(GET_BY_CATEGORY_STATS_LOADING, false));
        })
        .catch(err => dispatch(handleErrors(err)));
};

// Trends Stats
export const getIncomeExpenseTrend = (year) => dispatch => {
    dispatch(getLoadingDispatch(GET_BY_TRENDS_STATS_LOADING, true));
    api.callPost('api/userdata/getIncomeExpenseTrend', year, ENV.WEB_API)
    .then(data => {
        dispatch({
            type: GET_BY_TRENDS_STATS_SUCCESS,
            payload: data
        });
        dispatch(getLoadingDispatch(GET_BY_TRENDS_STATS_LOADING, false));
    })
    .catch(err => dispatch(handleErrors(err)));
};

// Trends Stats by category
export const getTrendByCategory = (year, category) => dispatch => {
    dispatch(getLoadingDispatch(GET_BY_TRENDS_CATEGORY_LOADING, true));
    api.callPost('api/userdata/getCategoryTrendByYear', {year: year, categoryId: category}, ENV.WEB_API)
    .then(data => {
        dispatch({
            type: GET_BY_TRENDS_CATEGORY_SUCCESS,
            payload: data
        });
        dispatch(getLoadingDispatch(GET_BY_TRENDS_CATEGORY_LOADING, false));
    })
    .catch(err => dispatch(handleErrors(err)));
};

// Send statement
export const sendStatement = (data) => dispatch => {
    dispatch(getLoadingDispatch(SEND_USER_STATEMENT, true));
    let dataObj = {
        startTime: moment(data.startTime).utc().valueOf(),
        endTime: moment(data.endTime).utc().valueOf(),
        type: +data.type
    };
    if(data.isAccountStatmentReq === 2) {
        dataObj['isAccountStatmentReq'] = true;
    }
    api.callPost('api/userdata/sendStatement', dataObj, ENV.WEB_API)
    .then(data => {
        dispatch({
            type: SEND_USER_STATEMENT_SUCCESS,
            payload: data
        });
        dispatch(getLoadingDispatch(STATEMENT_ACTION, 'EMAIL_SENT'));
        dispatch(getLoadingDispatch(SEND_USER_STATEMENT, false));
        dispatch(getLoadingDispatch(STATEMENT_ACTION, ''));
    })
    .catch(err => dispatch(handleErrors(err)));
};

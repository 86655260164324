import {ENV, umediaUrl} from '../actions/config';
import axios from 'axios';
import {
    logoutUser
} from "../actions/authActions";
import { history } from '../_helpers';
import store from '../store';
const feedBackUrl = 'https://feedback.timelybills.app/';
export const callGet = (service,url) =>
    
    new Promise((resolve, reject) =>{
        if(!url){
            url = ENV.API;
        }   
        axios.get(`${url}${service}`)
            .then(res => {
                if(res.data.code===0) {
                    return resolve(res.data);
                } else {
                    return reject(res);
                }
            })
            .catch(err => {
                if(err.response && err.response.status === 401) {
                    store.dispatch(logoutUser);
                    history.push({
                        pathname: '/signin'
                    });
                }else if(err && err.response && err.response.status === 404){
                    store.dispatch(logoutUser);
                    history.push({
                        pathname: '/page_not_found'
                    });
                }
                return reject(err.response);
            })
   } );
export const umediaGet = (service) =>
    new Promise((resolve, reject) =>
        // axios.get('https://umedia.timelybills.app/media/5e5b59e145e2611d9481325e/1589709672955_sign.png', {responseType: 'blob'})
        axios.get(`${umediaUrl}${service}`, {responseType: 'blob'})
            .then(res => {
                let image = URL.createObjectURL(res.data);
                if (res.status === 200) {
                    return resolve(image);
                } else {
                    return reject(res);
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    store.dispatch(logoutUser);
                    history.push({
                        pathname: '/signin'
                    });
                } else if (err && err.response && err.response.status === 404) {
                    store.dispatch(logoutUser);
                    history.push({
                        pathname: '/page_not_found'
                    });
                }
                return reject(err.response);
            })
    );

export const feedbackGet = (service) =>
    new Promise((resolve, reject) =>
        axios.get(`${feedBackUrl}${service}`)
            .then(res => {
                return resolve(res.data);
            })
            .catch(err => {
                if(err.response && err.response.status === 401) {
                    store.dispatch(logoutUser);
                    history.push({
                        pathname: '/signin'
                    });
                }else if(err && err.response && err.response.status === 404){
                    store.dispatch(logoutUser);
                    history.push({
                        pathname: '/page_not_found'
                    });
                }
                return reject(err.response);
            })
    );


export const callPost = (service, data, url) =>
    new Promise((resolve, reject) => {
        if(!url){
            url = ENV.API;
        }
        return axios.post(`${url}${service}`, data)
            .then(res => {
                if(res.data.code===0) {
                    return resolve(res.data);
                } else {
                    return reject(res);
                }
            })
            .catch(err => {
                if(err.response && err.response.status === 401) {
                    store.dispatch(logoutUser);
                    history.push({
                        pathname: '/signin'
                    });
                }else if(err && err.response && err.response.status === 404){
                    store.dispatch(logoutUser);
                    history.push({
                        pathname: '/page_not_found'
                    });
                }
                return reject(err.response);
            })
    });

export const uMediaPost = (data) =>
    new Promise((resolve, reject) =>
        axios.post(`${umediaUrl}${'media'}`, data)
            .then(res => {
                if(res.data.code===0) {
                    return resolve(res.data);
                } else {
                    return reject(res);
                }
            })
            .catch(err => {
                if(err.response && err.response.status === 401) {
                    store.dispatch(logoutUser);
                    history.push({
                        pathname: '/signin'
                    });
                }else if(err && err.response && err.response.status === 404){
                    store.dispatch(logoutUser);
                    history.push({
                        pathname: '/page_not_found'
                    });
                }
                return reject(err.response);
            })
    );

export const feedbackPost = (service, data) =>
    new Promise((resolve, reject) =>
        axios.post(`${feedBackUrl}${service}`, data)
            .then(res => {
                    return resolve(res.data);
            })
            .catch(err => {
                if(err && err.response && err.response.status === 404){
                        store.dispatch(logoutUser);
                        history.push({
                            pathname: '/page_not_found'
                        });
                }
                return reject(err.response);
            })
    );

export const callPut = (service, data) =>
    new Promise((resolve, reject) =>
        axios.put(`${ENV.API}${service}`, data)
            .then(res => {
                if(res.data.code===0) {
                    return resolve(res.data);
                } else {
                    return reject(res);
                }
            })
            .catch(err => {
                if(err.response && err.response.status === 401) {
                    store.dispatch(logoutUser);
                    history.push({
                        pathname: '/signin'
                    });
                }else if(err && err.response && err.response.status === 404){
                    store.dispatch(logoutUser);
                    history.push({
                        pathname: '/page_not_found'
                    });
                }
                return reject(err.response);
            })
    );

export const feedbackPut = (service, data) =>
    new Promise((resolve, reject) =>
        axios.put(`${feedBackUrl}${service}`, data)
            .then(res => {
                    return resolve(res.data);
            })
            .catch(err => {
                if(err.response && err.response.status === 401) {
                    store.dispatch(logoutUser);
                    history.push({
                        pathname: '/signin'
                    });
                }else if(err && err.response && err.response.status === 404){
                    store.dispatch(logoutUser);
                    history.push({
                        pathname: '/page_not_found'
                    });
                }
                return reject(err.response);
            })
    );

export const getImage = (service) => axios.get(`${ENV.API}${service}`,  { responseType: 'arraybuffer' });

export const getNextExpenseData = (service, data) => axios.post(`${ENV.API}${service}`, data);

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AppLayout from './AppLayout';
import ProFeature from "../ProFeature";
import {Helmet} from "react-helmet";

export const PrivateRoute = ({ component: Component, isProFeature, ...rest }) => {
	const getLocalData = JSON.parse(localStorage.getItem('profileDetails') || '{}');
	return (
		<Route
			{...rest}
			render = {
				props => (
					localStorage.getItem('jwtToken')
						? 	<AppLayout>
							<Helmet>
                			<title>TimelyBills</title>
                			</Helmet>
                            {
                                isProFeature && !getLocalData.isPro ?
                                    <ProFeature /> :
									<Component {...props} />
                            }
							</AppLayout>
						: 	<Redirect to = {{
								pathname: '/signin',
								state: { from: props.location }
							}}
						/>
				)
			}
		/>
	);
};

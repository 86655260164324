import React, {Component} from 'react';
import {Link, withRouter, NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {logoutUser} from "../actions/authActions";
import {
    fetchUserProfileDetails,
} from "../actions/settingsActions";
import {
    getMedia, getProfileImage
} from "../actions/changePassword";
import Sidenav from './SideNav';
import {
    Dropdown,
    Button,
    Icon,
} from 'react-materialize';
import '../stylesheets/App.css'
import i18n from '../local/i18n';
import {withTranslation, Trans} from 'react-i18next';
import {iconUrl} from "../actions/config";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
        searchVisible: "",
        imagePath: null,
        userSettings: JSON.parse(localStorage.getItem('profileDetails')) || {},
        userInFamilyGroup: localStorage.getItem('familyShare') || false
        };
        this.navbarDetails = {}
    }
    seachBarToggle = () => {
        const css = (this.state.searchVisible === "" ) ? 'expanded' : "";
        this.setState({searchVisible: css});
        document.getElementById("search").focus();
    };

    onLogOutClick = (event) => {
        event.preventDefault();
        this.props.logoutUser();
    };

    componentWillMount() {
        const userSettings = this.state.userSettings;
        if(this.props.auth.isAuthenticated && !localStorage.getItem('navbarDetails')) {
            //satisfied the firstTime as not present in localstorage
            this.props.fetchUserProfileDetails();
        }
        else if(this.props.auth.isAuthenticated && localStorage.getItem('navbarDetails') ){
            //every other time taken from localstorage in order to avoid API hit
            this.navbarDetails = JSON.parse(localStorage.getItem('navbarDetails'));
            this.name = this.navbarDetails.firstName + ' ' + this.navbarDetails.lastName;
            this.getAvatarImage(this.navbarDetails.profilePic);
        }
        if (userSettings.languageCode && this.props.i18n.language !== userSettings.languageCode) {
            i18n.changeLanguage(userSettings.languageCode);
        } else if (!userSettings.languageCode) {
            i18n.changeLanguage(window.navigator.language);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth && nextProps.auth.userDetails && nextProps.auth.userDetails.fetchUserProfile) {
            this.name = nextProps.auth.userDetails.fetchUserProfile.firstName + ' ' + nextProps.auth.userDetails.fetchUserProfile.lastName;
            if (this.props.auth && this.props.auth.userDetails && this.props.auth.userDetails.fetchUserProfile && this.props.auth.userDetails.fetchUserProfile.profilePic !== nextProps.auth.userDetails.fetchUserProfile.profilePic) {
                this.getAvatarImage(nextProps.auth.userDetails.fetchUserProfile.profilePic);
            }
        }
    }

    getAvatarImage = (fileName) => {
        if (fileName && fileName !== '') {
            getProfileImage(fileName, (imageData, status= true) => {
                if(status === true) {
                    this.setState({
                        imagePath: imageData
                    });
                }
            });
        }
        if(fileName === ''){
            this.setState({
                imagePath: iconUrl + "account.png"
            });
        }
    };

    render() {
        const {
            disableMenu = false,
            auth: {
                user = {},
                userDetails = {},
                isAuthenticated,
            }
        } = this.props;
        let fullName = user.email;
        const { fetchUserProfile } = userDetails;
        if(fetchUserProfile && (fetchUserProfile.firstName || fetchUserProfile.lastName)) {
           fullName = (fetchUserProfile.firstName || '') + ' ' + (fetchUserProfile.lastName || '');
        }
        const tempT = (
            <div className='user-options' style={{ display: 'flex', alignItems: 'center' }}>
                {this.state.userInFamilyGroup ? 
                <div className='group-mode-label'
                    style={{
                        // fontSize: '1rem',
                        fontFamily:'Helvetica, Arial, sans-serif',
                        marginRight: '5px',
                        // fontWeight: 'bold',
                        color: '#ffffff',
                        height: '37px',
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        padding: '4px 4px',
                        borderRadius: '1px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.30)',
                        textFillColor: 'transparent'
                    }}
                    >
                <Trans>GROUP_MODE</Trans>
                </div> :null}
                <Dropdown
                    trigger={
                        <Button className="header-dropdown-button">
                            <div className="header-avatar-div">
                            {this.state.imagePath ? <img src={this.state.imagePath} alt=""/>: <img src={iconUrl + 'account.png'} alt="" />}
                            </div>
                            <div className="header-name-arrow">
                                <div className="email-id-title">{fullName}</div>
                            <Icon right>expand_more</Icon>
                            </div>
                        </Button>
                    }
                    options={{coverTrigger: false}}>
                    <li>
                        <div className="header-dropdown-button">
                            <div className="header-avatar-div header-user-icons">
                                {this.state.imagePath ? <img src={this.state.imagePath} alt=""/>: <img src={iconUrl + 'account.png'} alt=""/>}
                                <div>{fullName}</div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <Link to="/account">
                            <i className="material-icons">
                                lock
                            </i>
                            <Trans>PROFILE</Trans>
                        </Link>
                    </li>
                    <li>
                        <NavLink to="/account#settings">
                            <i className="material-icons prefix">settings</i>
                            <Trans>SETTINGS</Trans>
                        </NavLink>
                    </li>
                    <li className="divider" tabIndex="-1"/>
                    <li onClick={this.onLogOutClick.bind(this)}>
                        <a>
                            <i className="material-icons">power_settings_new</i>
                            <Trans>SIGN_OUT</Trans>
                        </a>
                    </li>
                </Dropdown>
            </div>
        );
        const guestLinks = (this.props.disableSideLink ? <></> :
            
            <ul>
                <Link to="/signin" className="right">
                    <Trans>SIGN_IN</Trans>
                </Link>
                <Link to="/signup" className="right">
                    <Trans>REGISTER</Trans>
                </Link>
            </ul>);
        const link = (isAuthenticated ? "/dashboard" : "/signin");
        return (
            <div>
                {isAuthenticated ? <Sidenav/> : ''}
                <div className="navbar-fixed">
                    <nav className="nav-extended blueback2 z-depth-0">
                        <div className="nav-wrapper">
                            <Link to={link} className="brand-logo">
                                <img src={iconUrl + 'logo_main.png'} alt="logo" style={{"height": "50px", verticalAlign: "middle"}}/>
                                <span className="logo_text">TimelyBills</span>
                            </Link>
                            {disableMenu ? null : <a  data-activates="mobile-demo" className="left hide-on-large-only"><i
                                className="material-icons">menu</i></a> }
                            
                            {isAuthenticated ? tempT : guestLinks}
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}

Navbar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    fetchUserProfileDetails: PropTypes.func.isRequired,
    getMedia: PropTypes.func.isRequired,

};

const mapStateToProps = (state) => ({
    auth: state.auth,
    changePasswordData: state.changePasswordData,
    settings: state.settings,
});

export default withTranslation()(connect(mapStateToProps, {
    logoutUser,
    fetchUserProfileDetails,
    getMedia
})(withRouter(Navbar)));

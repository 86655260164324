import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import Spinner from "./common/Spinner";
import {withTranslation, Trans} from 'react-i18next';
import {
    getSettingsDetails,
    logoutUser
} from "../actions/authActions";
import {
    fetchUserProfileDetails,
    deleteUserAccount,
    sendDeletionOtp
} from "../actions/settingsActions";

import {
    changePassword,
    userUpdate,
    saveSettings,
    getMedia,getProfileImage,
    uploadMedia,
} from "../actions/changePassword";
import _ from 'lodash';
import {
    Row,
    Col,
    Icon,
    Button
} from 'react-materialize';
import '../stylesheets/App.css';
import Tabs from "./common/Tabs";
import Settings from './Settings'
import i18n from '../local/i18n';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {iconUrl} from "../actions/config";
import setAuthToken from "../utils/setAuthToken";
import { t } from 'i18next';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            email: '',
            firstName: '',
            imageFile: undefined,
            lastName: '',
            currentActiveTab: window.location.hash.replace('#', '') || 'profile',
            phoneNumber: undefined,
            dialCode: undefined,
            imageFileObj: {},
            imagePath: null,
            image: '',
            count: 1,
            viewMode: false,
            viewData: {},
            billNotifications: false,
            budgetNotifications: false,
            expenseNotifications: false,
            signInEmailNotificaitons: false,
            monthlyReportEmailNotificaitons: false,
            notificationTime: '',
            settings: {},
            changedValues: {},
            updateLoading: false,
            error: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.passwordChanging = false;
        this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
        this.props.getSettingsDetails();
        if(this.props.auth.isAuthenticated) this.props.fetchUserProfileDetails();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.changePasswordData.CHANGE_PASSWORD_LOADING && nextProps.changePasswordData.error) {
            this.getAlertBox(nextProps.changePasswordData.error.message, 'error');
            this.passwordChanging = false;
            this.clearPasswordForm()
        }
        if (!nextProps.changePasswordData.CHANGE_PASSWORD_LOADING && nextProps.changePasswordData.CHANGE_PASSWORD_ACTION) {
            this.getAlertBox('PASSWORD_UPDATED_SUCCESSFULLY', 'success');
            this.passwordChanging = false;
            this.clearPasswordForm()
        }
        if (nextProps.auth.userDetails) {
            if (nextProps.auth.userDetails.fetchUserProfile) {
                this.currentSettings = nextProps.auth.userDetails;
                this.currentDetails = nextProps.auth.userDetails.fetchUserProfile;
            }
        }
        if (nextProps.auth.userDetails && !nextProps.loading.loading) {
            if (nextProps.auth.userDetails.fetchUserProfile && nextProps.auth.userDetails.fetchProfile) {
                this.setState({
                    billNotifications: nextProps.auth.userDetails.fetchProfile.billNotifications,
                    budgetNotifications: nextProps.auth.userDetails.fetchProfile.budgetNotifications,
                    expenseNotifications: nextProps.auth.userDetails.fetchProfile.expenseNotifications,
                    notificationTime: nextProps.auth.userDetails.fetchProfile.notificationTime,
                    signInEmailNotificaitons: nextProps.auth.userDetails.fetchProfile.signInEmailNotificaitons,
                    monthlyReportEmailNotificaitons: nextProps.auth.userDetails.fetchProfile.monthlyReportEmailNotificaitons
                });
                this.currentSettings = nextProps.auth.userDetails.fetchProfile;
                this.setState({
                    settings: {...nextProps.auth.userDetails.fetchProfile}
                });
                this.setState({
                    firstName: nextProps.auth.userDetails.fetchUserProfile.firstName,
                    lastName: nextProps.auth.userDetails.fetchUserProfile.lastName,
                    phoneNumber: nextProps.auth.userDetails.fetchUserProfile.phoneNumber,
                    dialCode: nextProps.auth.userDetails.fetchUserProfile.dialCode,
                    email: nextProps.auth.userDetails.fetchUserProfile.email,
                });

                if (nextProps.auth.userDetails.fetchUserProfile.profilePic !== '') {
                    this.setState({image: nextProps.auth.userDetails.fetchUserProfile.profilePic});
                }
                if (this.props.auth && this.props.auth.userDetails.fetchUserProfile && this.props.auth.userDetails.fetchUserProfile.profilePic!== nextProps.auth.userDetails.fetchUserProfile.profilePic) {
                    this.getAvatarImage(nextProps.auth.userDetails.fetchUserProfile.profilePic);
                }
                if(this.state.count===1){
                    this.setState({count: this.state.count + 1});
                    this.getAvatarImage(nextProps.auth.userDetails.fetchUserProfile.profilePic);
                }
            }
        }
        if (nextProps.auth.settingData && nextProps.auth.settingData.currencies) {
            this.currencies = nextProps.auth.settingData.currencies.Currencies;
            this.timezones = nextProps.auth.settingData.timeZones.timezones;
            this.languages = nextProps.auth.settingData.languages.result;
        }
    };

    getAvatarImage = (fileName) => {
        if (fileName && fileName !== '') {
            getProfileImage(fileName, (imageData, status= true) => {
                if(status === true) {
                    this.setState({
                        imagePath: imageData
                    });
                }else {
                    this.getAlertBox((imageData || 'NO_IMAGE'), 'error');
                }
            });
        }
    };
    clearPasswordForm = () => {
        this.setState({
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        })
    };

    handlePhoneChange = (e, data) => {
        this.setState({
            phoneNumber: e,
            dialCode: data.dialCode
        });

    };

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    };


    handlePasswordSubmit() {
        const t = this.props.t;
        if (this.state.newPassword === this.state.confirmPassword) {
            const passwordData = {
                currentPassword: this.state.currentPassword,
                newPassword: this.state.newPassword,
                confirmPassword: this.state.confirmPassword
            };
            this.passwordChanging = true;
            this.props.changePassword(passwordData);
        } else {
            this.getAlertBox(t('CONFIRM_MISMATCH'), 'error');
        }
    };

    handleSettingChange = (key, e) => {
        let settingsData = this.state.settings;
        settingsData[key] = e.target.value;
        this.setState({
            settings: settingsData,
            changedValues: Object.assign(this.state.changedValues, {[key]: e.target.value})
        });
    };
    addMedia = (e, image) => {
        if (e) {
            this.setState({
                updateLoading: true
            });
            uploadMedia(e, image).then(() => {
                this.setState({
                    updateLoading: false
                });
                this.handleDetailSave(image);
            })
                .catch(err => {
                    console.error('err', err);
                })
        }
        else {
            this.setState({
                updateLoading: true
            });
            uploadMedia(this.state.imageFileObj, this.state.image).then(()=> {
                this.setState({
                    updateLoading: false
                });
                this.handleDetailSave();
            })
                .catch(err => {
                    console.error('err', err);
                })
        }
    };

    handleSave() {
        if (this.state.image && this.currentDetails.profilePic !== this.state.image) {
            this.addMedia();
        }
        else {
            this.handleDetailSave();
        }
        if(!_.isEmpty(this.state.changedValues)) {
            this.handleSettingSave();
        }
    };

    handleDetailSave = (e) => {
        if (e === 'fromClearAvatar') {
            let form = {
                profilePic: '',
                phoneNumber: this.state.phoneNumber,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                dialCode: this.state.dialCode
            };
            this.setState({
                updateLoading: true
            });
            this.props.saveSettings(form, () => {
                this.setState({
                    updateLoading: false
                });
                this.getAlertBox('USER_UPDATE', 'success');
            });
        }
        else if (e) {
            let form = {
                profilePic: e,
                phoneNumber: this.state.phoneNumber,
                dialCode: this.state.dialCode,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
            };
            this.setState({
                updateLoading: true
            });
            this.props.saveSettings(form, () => {
                this.setState({
                    updateLoading: false
                });
                this._fetchUpdatedProfile();
                this.getAlertBox('USER_UPDATE', 'success');
            });
        }
        else {
            if (this.currentDetails.firstName !== this.state.firstName || this.currentDetails.lastName !== this.state.lastName || this.currentDetails.phoneNumber !== this.state.phoneNumber || this.currentDetails.profilePic !== this.state.image) {
                let form = {
                    profilePic: this.state.image,
                    phoneNumber: this.state.phoneNumber,
                    dialCode: this.state.dialCode,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                };
                this.setState({
                    updateLoading: true
                });
                this.props.saveSettings(form, () => {
                    this.setState({
                        updateLoading: false
                    });
                    this.getAlertBox('USER_UPDATE', 'success');
                });
            }
        }
    };
    
    handleSettingSave = () => {
        if (!_.isEmpty(this.state.changedValues)) {
            let data = { ...this.state.changedValues };
            this.setState({
                updateLoading: true
            });
            this.props.saveSettings(data, () => {
                this._fetchUpdatedProfile();
                this.setState({
                    updateLoading: false,
                    changedValues: {}
                });
                this.getAlertBox('USER_UPDATE', 'success');
            });
        }
        else {
            this.getAlertBox('NO_CHANGE_DETECTED', 'error');
        }
    };
        _fetchUpdatedProfile = () => {
            this.props.fetchUserProfileDetails();
            const userLanguage = this.state.changedValues.languageCode;
            if (userLanguage && this.props.i18n.language !== userLanguage) {
                const userData = JSON.parse(localStorage.getItem('profileDetails'));
                userData.languageCode = userLanguage;
                localStorage.setItem('profileDetails', JSON.stringify(userData));
                i18n.changeLanguage(userLanguage);
                const token = localStorage.getItem('jwtToken');
                setAuthToken(token, userLanguage);
            }
        };

        getAlertBox = (message, type) => {
            const t = this.props.t;
            Swal.fire({
                title: t(message),
                position: 'bottom-start',
                toast: true,
                showConfirmButton: false,
                timer: 5000,
                background: type === 'success' ? '#8dc44e' : '#f1521c',
                customClass: {
                    title: 'sweet-alert',
                    container: 'sweet-alert-container'
                }
            });
        };


    clearAvatar() {
        this.setState({
            imagePath: '',
            image: '',
            imageFileObj: '',
            imageFile: '',
        });
        this.handleDetailSave('fromClearAvatar');
    }

    onFileChange = (key, e) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            this.setState({imagePath: e.target.result})
        };
        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
            let fileName = e.target.files[0].name;
            const extension = fileName.replace(/^.*\./, '');
            this.setState({
                [key]: 'TXIMG' + new Date().getTime() + '.' + extension,
                imageFileObj: e.target.files[0]
            });
            let image = 'TXIMG' + new Date().getTime() + '.' + extension;
            this.addMedia(e.target.files[0], image);
        }
    };

    setActiveTab = (tab) => {
        this.setState({
            currentActiveTab: tab.id
        });

    };
    //handles the changes done for setting component
    handleSettingComponentChanges = (e, key) => {
        if(key==='notificationTime'){
            this.setState({[key]: e.target.value,
                changedValues: Object.assign(this.state.changedValues, {[key]: e.target.value})
            });
        }
        else{
            this.setState({[key]: e.target.checked,
                changedValues: Object.assign(this.state.changedValues, {[key]: e.target.checked})
            });
        }
    };

    handleDeleteUser = () => {
        const email = this.state.email;
        const resendOtp = () => {
            this.props.sendDeletionOtp(email);
        };
        this.props.sendDeletionOtp(email);
        const t = this.props.t;
        Swal.fire({
            title: t('DELETE_PROFILE?'),
            html: `
                <p>${t('PROFILE_DELETE_MESSAGE')}</p>
                <p>${t('PROFILE_DELETE_MESSAGE1')}</p>
                <label for="otp">${t('PROFILE_DELETE_MESSAGE2')}</label>
                <div class="swal2-input-container" style="display: flex; flex-direction: column; align-items: flex-end;">
                    <input type="text" id="otp" class="swal2-input" placeholder="${t('ENTER_OTP')}" required>
                    <label id="resend-link" style="cursor: pointer; color: blue;">${t('RESEND_OTP')}</label>
                </div>
            `,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#b8b8b8',
            confirmButtonText: t('DELETE'),
        }).then((result) => {
            if (result.value) {
                    const enteredOTP = document.getElementById('otp').value;
                    this.setState({
                        updateLoading: true
                    });
                    deleteUserAccount(enteredOTP, email)
                    .then(() => {
                        this.setState({
                            updateLoading: false
                        });
                        Swal.fire({
                            text: t('PROFILE_DELETE_SUCCESS'),
                            confirmButtonColor: '#d33',
                            confirmButtonText: t('SIGN_OUT'),
                            allowOutsideClick: false,
                        }).then((logoutResult) => {
                            if (logoutResult.value) {
                                this.props.logoutUser();
                            }
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            updateLoading: false
                        });
                        console.log(error);
                        this.getAlertBox(('INVALID_OTP'), 'error');
                    });
            }
        });
        document.getElementById('resend-link').addEventListener('click', resendOtp);
    }

    render() {
        const loading = this.props.loading.loading;
        const {t} = this.props;
        const {
            settings: {
                currency,
                timeZoneId,
                languageCode
            },
            updateLoading
        } = this.state;
        return (
            <div>
                <div className="vertical-center">
                    <div className="row margin-0">
                        <div className="col m1 l1"/>
                        <div className="col l12 m12 s12 ">
                            <div className="card darken-1">
                                <div className="card-content change-password">
                                    <Row>
                                        <Col s={12} m={12} l={3} className="Profile-mobile">
                                            <div className="choose-file-input">
                                                {this.state.imagePath ?
                                                    <div className="profile-picture">
                                                        <img src={this.state.imagePath}
                                                             alt=""
                                                             className="profile-image"/>
                                                        <i className="material-icons">
                                                            camera_enhance
                                                        </i>
                                                        <input
                                                            id="icon_prefix"
                                                            title=""
                                                            accept="image/*"
                                                            type="file"
                                                            value={this.state.imageFile}
                                                            onChange={this.onFileChange.bind(this, 'image')}
                                                            name="file"/>
                                                        <i className="material-icons clear"
                                                           onClick={this.clearAvatar.bind(this)}>
                                                            clear
                                                        </i>
                                                    </div> : <div className="profile-picture">
                                                        <img src={iconUrl + 'account.png'}
                                                             alt=""
                                                             className="profile-image"/>
                                                        <i className="material-icons">
                                                            camera_enhance
                                                        </i>
                                                        <input
                                                            id="icon_prefix"
                                                            type="file"
                                                            accept="image/*"
                                                            title="Upload File"
                                                            value={this.state.imageFile}
                                                            onChange={this.onFileChange.bind(this, 'image')}
                                                            name="file"/>
                                                    </div>}
                                            </div>

                                        </Col>
                                        <Col s={12} m={12} l={9} className='billing-tabs'>
                                            <Tabs
                                                receivceProps={true}
                                                activeTab={{
                                                    id: this.state.currentActiveTab
                                                }}
                                                onClick={this.setActiveTab}>
                                                <Tabs.Tab
                                                    title={
                                                        <div className='tab-title' title={t('PROFILE')}>
                                                            <Trans>PROFILE</Trans>
                                                            <br/>
                                                        </div>
                                                    }
                                                    id='profile'>
                                                    <div className="flex-container">
                                                        <div className="column input-min-height">
                                                            <div className="input-block">
                                                                <div className="input-field">
                                                                    <input type="text"
                                                                           name="email"
                                                                           value={this.state.email}
                                                                           disabled={true}/>
                                                                    <label htmlFor="icon_prefix"
                                                                           className={this.state.email ? 'active' : ''}><Trans>EMAIL</Trans></label>
                                                                </div>
                                                            </div>
                                                            <div className="input-block">
                                                                <div className="input-field">
                                                                    <input type="text" onChange={this.handleChange}
                                                                           name="firstName"
                                                                           value={this.state.firstName}
                                                                           required/>
                                                                    <label htmlFor="icon_prefix"
                                                                           className={this.state.firstName ? 'active' : ''}><Trans>FIRST_NAME</Trans></label>
                                                                </div>
                                                            </div>
                                                            <div className="input-block">
                                                                <div className="input-field ">
                                                                    <input type="text" onChange={this.handleChange}
                                                                           value={this.state.lastName}
                                                                           name="lastName"
                                                                           required/>
                                                                    <label htmlFor="icon_prefix"
                                                                           className={this.state.lastName ? 'active' : ''}><Trans>LAST_NAME</Trans></label>
                                                                </div>
                                                            </div>
                                                            <div className="input-block">
                                                                <div className="input-field">
                                                                    <PhoneInput
                                                                        country={'us'}
                                                                        value={this.state.phoneNumber}
                                                                        inputClass="phone-input"
                                                                        onChange={this.handlePhoneChange}/>
                                                                    <label htmlFor="icon_prefix"
                                                                           className='active'><Trans>PHONE_NUMBER</Trans></label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Button
                                                        style={{ marginRight: '10px' }}
                                                        key={'settingActionButton'}
                                                        className="btn waves-effect waves-light light-blue accent-4"
                                                        onClick={this.handleSave.bind(this)}
                                                        disabled={loading}>
                                                        <Trans>SAVE</Trans>
                                                    </Button>
                                                    <Button
                                                        key={'settingDeleteButton'}
                                                        className="btn waves-effect waves-light light-blue accent-4"
                                                        onClick={this.handleDeleteUser}
                                                        disabled={loading}>
                                                        <Trans>DELETE_PROFILE</Trans>
                                                    </Button>
                                                </Tabs.Tab>
                                                
                                                <Tabs.Tab
                                                    title={
                                                        <div className='tab-title' title={t('SETTINGS')}>
                                                            <Trans>SETTINGS</Trans>
                                                            <br/>
                                                        </div>
                                                    }
                                                    id='settings'>
                                                    <div className="flex-container">
                                                        <div className="column input-min-height ">
                                                            <div className="input-block">
                                                                <i className="fas prefix">
                                                                    <Icon>attach_money</Icon>
                                                                </i>
                                                                <div className="input-box">
                                                                    <label htmlFor="currency"
                                                                           className={currency ? 'active' : ''}>
                                                                        <Trans>CURRENCY</Trans>
                                                                    </label>
                                                                    <select
                                                                        onChange={this.handleSettingChange.bind(this, 'currency')}
                                                                        placeholder={t('SELECT_CURRENCY')}
                                                                        name='currency'
                                                                        value={currency}
                                                                        style={{display: 'block'}}>
                                                                        <option value='0'
                                                                                disabled>{t('SELECT_CURRENCY')}</option>
                                                                        {this.currencies ?
                                                                            this.currencies
                                                                                .map(({currencyCode, currencyName}) =>
                                                                                    <option
                                                                                        value={currencyCode}
                                                                                        key={currencyCode}
                                                                                    >
                                                                                        {`${currencyName}(${currencyCode})`}
                                                                                    </option>
                                                                                ) : null
                                                                        }
                                                                    </select>
                                                                </div>

                                                            </div>
                                                            <div className="input-block">
                                                                <i className="fas prefix">
                                                                    <Icon>language</Icon>
                                                                </i>
                                                                <div className="input-box">
                                                                    <label htmlFor="languageCode"
                                                                           className={languageCode ? 'active' : ''}>
                                                                        <Trans>LANGUAGE</Trans>
                                                                    </label>
                                                                    <select
                                                                        style={{display: 'block'}}
                                                                        name='languageCode'
                                                                        onChange={this.handleSettingChange.bind(this, 'languageCode')}
                                                                        value={languageCode}
                                                                        label={t('LANGUAGE')}
                                                                        placeholder={t('SELECT_LANGUAGE')}
                                                                    >
                                                                        <option value='0'
                                                                                disabled>{t('SELECT_LANGUAGE')}</option>
                                                                        {this.languages ?
                                                                            this.languages
                                                                                .map(({isoCode, name, _id}) =>
                                                                                    <option
                                                                                        value={isoCode}
                                                                                        key={_id}
                                                                                    >
                                                                                        {name}
                                                                                    </option>
                                                                                )
                                                                            : null}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="input-block">
                                                                <i className="fas prefix">
                                                                    <Icon>timelapse</Icon>
                                                                </i>
                                                                <div className="input-box">
                                                                    <label htmlFor="timeZoneId"
                                                                           className={timeZoneId ? 'active' : ''}>
                                                                        <Trans>TIME_ZONE</Trans>
                                                                    </label>
                                                                    <select
                                                                        style={{display: 'block'}}
                                                                        name='timeZoneId'
                                                                        onChange={this.handleSettingChange.bind(this, 'timeZoneId')}
                                                                        value={timeZoneId ? timeZoneId : 0}
                                                                        placeholder={t('SELECT_TIME_ZONE')}
                                                                    >
                                                                        <option value='0'
                                                                                disabled>{t('SELECT_TIMEZONE')}</option>
                                                                        {this.timezones ?
                                                                            this.timezones
                                                                                .map(({id, name, offset}) =>
                                                                                    <option
                                                                                        value={id}
                                                                                        key={id}
                                                                                    >
                                                                                        {`${name}(${offset})`}
                                                                                    </option>
                                                                                )
                                                                            : null}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Settings bill={this.state.billNotifications}
                                                                          budget={this.state.budgetNotifications}
                                                                          expense={this.state.expenseNotifications}
                                                                          signinNotification={this.state.signInEmailNotificaitons}
                                                                          reportNotification={this.state.monthlyReportEmailNotificaitons}
                                                                          notification={this.state.notificationTime}
                                                                          updateValue={this.handleSettingComponentChanges}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Button
                                                        key={'settingActionButton'}
                                                        className="btn waves-effect waves-light light-blue accent-4"
                                                        onClick={this.handleSave.bind(this)}
                                                        disabled={loading}>
                                                        <Trans>SAVE</Trans>
                                                    </Button>
                                                </Tabs.Tab>
                                            </Tabs>
                                            {this.passwordChanging || loading || updateLoading ?
                                                <Spinner/> : null}
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );


    }
}


ChangePassword.propTypes = {
    auth: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    getSettingsDetails: PropTypes.func.isRequired,
    saveSettings: PropTypes.func.isRequired,
    userUpdate: PropTypes.func.isRequired,
    getMedia: PropTypes.func.isRequired,
    fetchUserProfileDetails: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    sendDeletionOtp: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    loading: state.loading,
    changePasswordData: state.changePasswordData,
    currencies: state.categories,
    settings: state.settings,
    userData: state.userData,
    userData: state.userData,
    error: state.error
});

export default withTranslation()(connect(mapStateToProps, {
    changePassword,
    getSettingsDetails,
    userUpdate,
    saveSettings,
    fetchUserProfileDetails,
    getMedia,
    logoutUser,
    deleteUserAccount,
    sendDeletionOtp
})(withRouter(ChangePassword)));